import React, { Component } from 'react'
import no from './svg/checkboxUnchecked.svg'
import yes from './svg/checkboxChecked.svg'

class CheckBox extends Component {
  constructor(props, context) {
    super(props, context)

        this.state = {
            checked: this.props.init
        }
    }

  render() {
    let { text, callback } = this.props
    let checked = this.state.checked

    return (
        <div className='CheckBoxContainer' onClick={()=>{callback(!checked);this.setState({checked: !checked})}} >
          <img className='CheckBox' src={checked ? yes : no } alt='checkbox' />
          <span className='CheckBoxText' >{text}</span>
        </div >
      )
  }
}  

export default CheckBox


