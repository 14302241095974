/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/

import React, { Component } from 'react'
import { isAndroid } from 'react-device-detect'
import DocumentMeta from 'react-document-meta'
import Introduction from './Introduction'
import WDIntroduction from './WDIntroduction'
import CheckListItem from './CheckListItem'
import StartButton from './StartButton'
import PhotoAdd from './PhotoAdd'
import DocumentAdd from './DocumentAdd'
import TextBox from './TextBox'

import Section from './Section'
import Header from './Header'
import Submit from './Submit'
import Input from './OldSchoolInput'
import Warning from './Warning'
import PhotoDisplay from './PhotoDisplay'
import DocumentDisplay from './DocumentDisplay'
import UnknownElement from './UnknownElement'
import LocalDevIcon from './LocalDevIcon'
import SetupSuccess from './SetupSuccess'
import NewProtocol from './NewProtocol'
import IconSelector from './IconSelector'
import ShowTime from './ShowTime'
import Error from './Error'
import Start from './Start'
import Edit from './Edit'
import Note from './Note'

import CdapLogout from './variants/cdap/Logout'

import config from '../config/config.js'
import settings from '../config/settings.js'
import { log } from '../helpers/PrintHTMLDebug.js'




const FILE_NOT_FOUND = 'no such file'
const COMMA = '_c0mm4_'
const COMMA_RX = /_c0mm4_/g
const SEMICOLON = '_semic0lon_'
const SEMICOLON_RX = /_semic0lon_/g

// uqai.digsie.de/aiintro/%pid% da ist ein weiter button mws.makrolog.de/fb-aut (s.u. MWS_FBAUTH_URL) #ndert sich vermutlich in abhäüngigkeit der services (der domäne auf der der aiintro läuft)
// uqai.digsie.de/ai/%pid% -> Vorschauf auf mein aident derzeit geht der auf my-qr.io/ai/%pid% muss gehen auf www.digsie.de/%pid%

// const URL.DIGSIE = 'https://my-qr.io/ai' + (BASEDOMAIN.includes( 'uqrc' ) || BASEDOMAIN.includes( '192' ) ? 'rc/' : '/')
// const MWS_FBAUTH_URL = 'https://mws.makrolog.de/fb-auth' + (BASEDOMAIN.includes('uqrc') || BASEDOMAIN.includes('192') ? '-rc/' : '/')


const BASEDOMAIN = window.location.hostname.split('.')[0]
const LOCALDEV = BASEDOMAIN.includes('192') || BASEDOMAIN.includes('localhost')
//neverused// const UQRC_DEPLOYMENT = BASEDOMAIN.includes('uqrc')

//neverused// const FORCE_RC = window.getURLParameter('forcerc') === 'true'
//neverused// const RC = UQRC_DEPLOYMENT || LOCALDEV || FORCE_RC ? '-rc/' : '/'

const URL = {
    AI_FBAUTH: 'https://www.digsie.de/fb-auth-ai/',
    AD_FBAUTH: 'https://www.digsie.de/fb-auth-ad/',
    VB_FBAUTH: 'https://www.digsie.de/fb-auth-vb/',
    BI_FBAUTH: 'https://a-dok.digsie.de/fb-auth',
    CDAP_FBAUTH: 'https://web.corona-presence.de/webuicdap/',
    WD_FBAUTH: 'https://web.whatsdown.com/login_fbauth/?service=webwhatsdown',
    DIGSIE: 'https://www.digsie.de/',
    CDAP: 'https://uqcda.corona-presence.de/',
    DF_WEB_VIEWER_AD: 'https://www.digsie.de/web_ad/',
    DF_WEB_VIEWER_VB: 'https://www.digsie.de/web_vb/',
    DF_WEB_VIEWER_CDAP: 'https://web.corona-presence.de/webuicdap/',
    DF_WEB_VIEWER_EAG: 'https://web.whatsdown.com/webuiwdeag/',
    WEB_WHATSDOWN: 'https://web.whatsdown.com/webuiwd/'
}

/* const DF_FBAUTH_RC = 'login_fbauth/?rc&service=%SERVICE%&qrid='
const DF_QUERY = '?rc&service=%SERVICE%&user=%SERVICE%&qrid=' */


// var headerIconUrl = 'unset'
const META = isAndroid ? {
    title: 'WhatsDown',
    description: 'WhatsDown Makrolog AG',
    meta: {
        charset: 'utf-8',
        name: {
            viewport: 'width=device-width, target-densitydpi=device-dpi, initial-scale=0, maximum-scale=1, user-scalable=yes'
        }/* ,
        property: {
            'og:image': headerIconUrl
          } */
    }
} : {}

const FILTER_TYPES = [
    'AUTH',
    'auth',
    'redirect',
    'addfolders',
    'urlparams',
    'mws-parameter',
    '___'
]




String.prototype.alive = function () {
    let t = getJwt()
    let i = t.invalid
    let s = Boolean(t.string)
    let d = new Date().getTime()
    return !i && s && d < (t.exp * 1000)
}

String.prototype.expired = function () {
    let t = getJwt()
    let i = t.invalid
    let s = Boolean(t.string)
    let d = new Date().getTime()
    return !i && s && d > (t.exp * 1000)
}

String.prototype.invalid = function () {
    let t = getJwt()
    return t.invalid && t.string
}



const json = async (app, response) => {
    let exists = await response.json()
    console.log( 'json repsponse: %o', response )
    //let exists = JSON.parse(response)
    exists.app = app

    // validate response
    if (exists.code === 500)
        app.showError(settings.existsError.replace('%SERVER_RESPONSE', exists.response))

    if (exists.code === 404)
        app.showError(settings.notFoundError)

    exists.failed = function () {
        return this.code === 404 || this.code === 500
    }



    // ALTES RESPONSE JSON
    /* 
    exists.containsResponse = function(){
      console.log('containsResponse %o', this)
      return ( 
        this.response && 
        this.response !== 'Not Found' && 
        typeof this.response.id !== 'undefined' && // 
        typeof this.response.data !== 'undefined' 
      )
    }*/

    const getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length).replace(SEMICOLON_RX, ';');
          }
        }
        return "";
      }

    exists.docPermid = function () {
        return (
            typeof this.permid !== 'undefined' &&
            this.permid !== null
        )
    }

    exists.success = function () {
        return this.response && this.response.success
    }

    exists.containsData = function () {
        return Boolean(this.renderData)
        // return this.renderData && this.response.data // undefined !(?)
    }

    exists.log = function () {
        return this
    }

    exists.mode = function () {
        let e = Boolean(exists.renderData)
        let t = getJwt().string.alive()
        let c = window.getURLParameter('mode') === 'checklist'
        let p = window.getURLParameter('display') === 'preview'
        let s = window.location.pathname.includes('setup')
        let l = getCookie('login_'+getQrid())
        let i = intro()
        let m = {
            LOADING: false,
            INTRO: (!e && !t && !c && !p) || i,
            SETUP: (!e && t && !c && !p) || s,
            VIEW: e && !t && !c && !p,
            EDIT: e && t && !c && !p,
            CHECKLIST: e && !t && c && !p,
            SETUP_SUCCESS: p,
            RENDER_DATA_IN_EXISTS: e,
            LOGGED_IN: Boolean(l)
        }

        m.NO_RIGHTS = /* m.EDIT && */ !p && !exists.permid
        m.EXISTS = Boolean(exists.permid)
        m.MENU = Boolean(exists.checkliste)
        m.PROTOCOL = Boolean(exists.protocol)

        console.log('mode %o: ', m)
        return m
    }


    exists.getPermIDs = function (that) {
        let e = this
        // console.log('e: %o', e)

        let parent, child

        if (e.success())
            try {
                parent = e.response.success.object[0]._
                child = e.response.success.object[0]._
            } catch (err) { }

        if (e.response && e.response.data)
            parent = e.response.data.permId

        // brute force/safety für ggf. falsch im resolver konfigurierte Etiketten (a-ident change 25.10.2019)
        if (that.template === 'ai') {
            parent = 'dBL3MZWMub'
        }

        if (!e.permid) {
            if (that.noChangeRightsRef) that.noChangeRightsRef.show() // TODO
        }

        return {
            CHILD: child || e.childpermid,
            PARENT: parent || e.permid,
            DOCUMENT: e.permid,
            CHECKLIST: e.checkliste,
            PROTOCOL: e.protocol
        }
    }

    return exists
}


const getJwt = function (token) {
    token = token || window.getURLParameter('idtoken')
    let json = { exp: 0, string: '', invalid: false }

    if (!token)
        return json

    try {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace('-', '+').replace('_', '/')
        json = JSON.parse(window.atob(base64))
    } catch (error) {
        console.error('Invalid JWT Token. ' + error)
        json.invalid = true
        return json
    }
    json.string = token
    return json
}


const getTemplateName = function () {
    let template = window.getURLParameter('template')
    let path = window.location.pathname.split('/')
    let pathname = path.length > 2 ? path[1] : ''

    return template ? template : pathname
}


const getQrid = function () {
    let qrid = window.getURLParameter('qrid')
    let path = window.location.pathname.split('/')
    let qrpath = path.length > 2 ? path[2] : path[1]

    return decodeURI(qrid ? qrid : qrpath)
}


const setup = function () {
    return getTemplateName().includes('setup')
}

const intro = function () {
    return getTemplateName().substr(2).includes('intro')
}

const introType = function () {
    // console.log('introType getTemplateName().substr(0, 2) %o', getTemplateName().substr(0, 2))
    return /* intro() ? */ getTemplateName().substr(0, 2) /* : '' */
}



/* var HTMLOUT = { 'isUndefined': false }

if( typeof window.HTMLOUT === 'undefined' )
  var HTMLOUT = { 'isUndefined': true } */

class App extends Component {
    constructor(props, context) {
        super(props, context)

        window.appjs = this

        this._componentDidMount = false
        this.renderData = null
        this.firstElement = null
        this.renderSavedInstance = false
        this.rundgangStartClass = 'greenBtn' // TODO: noch in Benutzung?
        this.skipFirstRender = true
        this.mode = { INTRO: intro(), SETUP: setup() }
        this.type = this.uqrcType(introType())
        this.permid = {}

        this.idtoken = getJwt().string // window.getURLParameter('idtoken') || '%notoken%'
        this.authorized = this.idtoken.alive() // ... idtoken.alive()

        this.uqrcServer = window.location.hostname + (LOCALDEV ? ':5000' : '/helper')


        this.http = window.location.href.split(':')[0] + '://'
        this.helper = this.http + this.uqrcServer


        this.displayPreview = window.getURLParameter('display') === 'preview'
        this.headerid = window.getURLParameter('headerid')
        this.edpPrefix = null

        this.template = getTemplateName()
        this.pid = getQrid()



        // let QRloc      = decodeURI(window.getURLParameter('location'))
        this.validated = false // TODO: ?: QRloc.includes(this.pid) && QRloc.length === this.pid.length
        this.validatedAccess = false
        this.overrideValidatedAccess = false // null | false | true
        this.headerElements = []
        this.cookie = { name : null, value: {}, complete: {} }

        this.send = this.send.bind(this)
        this.exists = this.exists.bind(this)
        this.JSONtoHTML = this.JSONtoHTML.bind(this)
        this.HTMLtoJSON = this.HTMLtoJSON.bind(this)
        this.setImagePath = this.setImagePath.bind(this)
        // this.setImageRef = this.setImageRef.bind(this)
        this.checklistFinished = this.checklistFinished.bind(this)
        this.inputKeyUpHandler = this.inputKeyUpHandler.bind(this)
        this.mwsCreateEDPObject = this.mwsCreateEDPObject.bind(this)
        this.activateVerifiedBtn = this.activateVerifiedBtn.bind(this)
        /* this.toggledCollapse = this.toggledCollapse.bind(this) */
        this.validate = this.validate.bind(this)
        this.setValidated = this.setValidated.bind(this)
        this.save_intermediate = this.save_intermediate.bind(this)
        this.uploadPhotos = this.uploadPhotos.bind(this)
        this.documentPermid = null
        this.createMode = null

        // speichern button on|off
        this.initialSubmitState = true
        this.state = {
            showSubmit: this.initialSubmitState
        }


        this.rows = []
        this.images = []
        this.inputs = []
        this.sections = []
        this.imageRefs = []
        this.fileTypes = []
        this.postmessage2edp = null
        this.createnotification = null
        this.logoutmessage = {}
        this.showCookieCheckbox = true
        this.cookiesAllowed = this.getCookie('cookiesAllowed') === 'false' ? false : true


        this.setDocNoteRef = (i, t) => {
            this.DocNoteRef = t
        }

        this.setLocationNoteRef = (i, t) => {
            this.LocationNoteRef = t
        }

        this.seNoChangeRightsRef = (t) => {
            this.noChangeRightsRef = t
        }

        this.DocumentNoteElem = <Note content='Dokumenten-Funktion bald verfügbar!' setNoteRef={this.setDocNoteRef} />
        this.LocationNoteElem = <Warning content='Falscher Standort!' setNoteRef={this.setLocationNoteRef} />
        this.noChangeRights = <Warning content='Änderung nicht möglich. Telefonnummer passt nicht zum Code.' setNoteRef={(t) => this.seNoChangeRightsRef(t)} />

        /* this.setRowRef = (key, e) => {
          this.rows[key] = e
        } */

        this.setImageRef = (key, e, fileType) => {
            this.imageRefs[key] = e

            // console.log('setImageRef fileType: %o', fileType)

            if (fileType)
                this.fileTypes[key] = fileType
        }

        this.resetImageRef = (key) => {
            delete this.imageRefs[key]
        }

        this.setStartButtonRef = (e) => {
            this.startButton = e
        }

        this.closeCurrent = (id) => {
            let self = this
            setTimeout(function () {
                if (self.rows[id].state.isOpen)
                    self.rows[id].toggleOpen()
            }, 30)
        }


        this.closeOthers = (id) => {
            setTimeout(function () {
                for (var key in this.rows) {
                    let k = key * 1 // str2int
                    if (k !== id && this.rows[k].state.isOpen) {
                        this.rows[k].toggleOpen()
                    }
                }
            }.bind(this), 40)
        }


        this.openNext = (id) => {
            let i = id + 1

            if (this.rows[id].state.choice)
                return

            if (!this.rows[i])
                i++

            if (!this.rows[i])
                return

            setTimeout(function () {
                if (!this.rows[i].state.isOpen) // !null
                    this.rows[i].toggleOpen()
            }.bind(this), 50)
        }
    }

    setHeaderIconUrl(icon){
        //neverused// var headerIconUrl = icon
    }


    setDocTitleAndFavicon() {
        let { AI, AD, BI, WD, VB, CDA, CDAP } = this.uqrcType()
        let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'

        let meta = [document.title, link.href]
        if (AI) meta = ['A-Ident', '/aifavicon.ico']
        if (AD) meta = ['A-Dok', '/aifavicon.ico']
        if (BI) meta = ['Boehringer', '/aifavicon.ico']
        if (WD) meta = ['WhatsDown', '/favicon.ico']
        if (VB) meta = ['1stad', '/vbfavicon.ico']
        if (CDA) meta = ['cda', '/favicon.ico']
        if (CDAP) meta = ['cdap', '/favicon.ico']

        //todo vbicon (1stad) - weisse kreuz auf grünem grund 00855a
        document.title = meta[0] + ' (' + this.pid + ')'
        link.href = meta[1]

        document.getElementsByTagName('head')[0].appendChild(link)
        return true
    }



    async initFromAppOrServer() {
        // console.log('initFromAppOrServer')
        //instanz noch nicht vorhanden - instanz gemäss template initialisieren
        if (!window.webkit /*|| this.mode.SETUP  window.location.pathname.includes('setup') */) {
            return await this.initfromserver()
        } else { // TODO Android
            //Setup initialisiert sich nie von der APP, daher hier im Moment (050719) keine Android-Variante erforderlich
            //am besten in der config eine kommagetrennte liste aufnehmen, welche Templates lokale Templates sind
            //noch besser: Die App weiss, ob sie ein template hat und gibt ansonsten ein leeres json z.b.
            //{"template":"local"} zurück
            //return await this.initfromapp() //TODO: Irgedwann mal den doch noch möglichen fehlerfall, dass von der app 0 zurückkommt abfangen
            return await this.initfromserver()
            // try catch, error page
        }
        // Idee evt. die maksierte Telefonnummer anzeigen, so dass man eine idee bekommt, welche es sein könnte die ersten 3-4 (0172.../die letzen 3 stellen)
        //this.forceUpdate()

    }


    componentDidUpdate() {

        this.setDocTitleAndFavicon()
    }


    async componentDidMount() {

        if (!this.pid)
            return this.showError('Missing QR Code ID')

        if (this.idtoken.expired())
            this.showError('Token timed out')

        if (this.idtoken.invalid())
            this.showError('Invalid token')

        //SAFARI COOKIE DEBUGGING
        // return null
        
        var exists = await this.exists()
        if (exists.failed())
            this.showError('Network Error. MWS failed.')

            

        this.renderData = exists.renderData
        this.permid = exists.getPermIDs(this)
        this.mode = exists.mode()
        this.displayTitle = exists.title
        this.last_update = exists.last_update

        console.log('this.mode %o', this.mode)


        let { INTRO, SETUP, VIEW, EDIT } = this.mode
        if ((INTRO || SETUP) /* && !EXISTS */) {

            this.hideLoader()
            this.renderData = await this.initFromAppOrServer()
        }

        this.headerid = this.getRenderDataElement('header', 'id')

        //if (VIEW || EDIT)
        this.type = this.uqrcType()

        // console.log('this.renderData', this.renderData)

        // this.createMode = /* mode.SETUP || */ this.mode.EDIT // historisch: ('response' hieß: überhaupt eine antwort vom server, kein error) exists.containsResponse()
        // this.renderSavedInstance = this.mode.VIEW  // !this.editMode() 

        // wird ins menu ausgelagert ? 
        //neverused// let { MENU } = this.mode
        let { AD } = this.type

        if ((AD) && (VIEW || EDIT))
            await this.redirect2login() // redirect2login unterscheidet dann zwischen login und VIEW anonym also login/EDIT
        /* else if ((VB) && EDIT && !MENU)
            await this.redirect2login()  */// redirect2login unterscheidet dann zwischen login und VIEW anonym also login/EDIT
        else
            this._componentDidMount = true

        /* if ( (this.type.VB) && (this.mode.VIEW || this.mode.EDIT))
        this.VBMenu()

        if ( (this.type.WD) && (this.mode.VIEW || this.mode.EDIT))
        this.WDMenu()
*/
        // no rights: wenn token bzw EDIT-mode, aber keine docpermid
        if (this.mode.NO_RIGHTS) {/* !this.createMode &&  */ //create=setup ? create=mwsCreate?
            this.noRigthsPage(exists.extension)

        }


        this.hideLoader()
        this.forceUpdate()
    }




    setBrowserPageTitle(t) {
        document.title = t ? t : document.title + ' (' + this.pid + ')'
    }

    // sucht daten-feld 'field' aus erstem elment von typ 'type'
    getRenderDataElement(type, field, id) {
        let ret = ''
        let json = this.renderData

        // console.log('getRenderDataElement this.renderData', this.renderData)

        if (json)
            json.some(function (el) {
                if(id){
                    // console.log('getRenderDataElement id ', id)
                    if (el.id === id) {
                        ret = el[field]
                        return true // stop iteration
                    } else
                        return false
                } else {
                    if (el.type === type) {
                        if(field)
                            ret = el[field]
                        else
                            ret = el
                        return true // stop iteration
                    } else
                        return false
                }
            })

        return ret
    }


    joinObjectParams(json, params) {
        if (params) {
            let { title, dvid } = typeof params === 'string' ? JSON.parse(params) : params
            if (title && dvid)
                json.some(function (el) {
                    if (el.type === 'introduction') {
                        el.name = title
                        el.dvid = dvid
                        return true // stop execution
                    }
                    return false
                })
        }
        return json
    }


    async initfromapp() {
        let result = await window.readStringFromFile('')
        let json = JSON.parse(result)
        let params = await window.getobjectparameters()

        return this.joinObjectParams(json, params)
    }


    async initfromserver() {
        // console.log('initfromserver() this.template %o', this.template)
        if (this.template) {
            // this.permid.PARENT
            //const template = await fetch(this.helper + '/tmpl?tmpl=' + this.template + '&parentid=' + )
            const template = await fetch(this.helper + '/tmpl?tmpl=' + this.template + '&parentid=' + this.permid.PARENT+ '&service=' + this.type.SERVICE+ '&qrid=' + this.pid)
            const tmpl = await template.json()

            if (tmpl.express !== FILE_NOT_FOUND)
                return (JSON.parse(tmpl.express))
        }

        return (config)
    }


    hideLoader() {
        document.querySelector('#loader').style.display = 'none'
        document.querySelector('#loading').style.display = 'none'
        return true
    }


    async getToken() {
        if (window.webkit) return await window.requestAK()
        else if (isAndroid && typeof window.HTMLOUT !== 'undefined') return window.HTMLOUT.getIdToken()
        return this.idtoken
    }

    async post(path, data = {}) {

            data.qrid = this.pid
            data.token = await this.getToken()
            data.checklist = window.getURLParameter('mode') === 'checklist'

            return await fetch(this.helper + path,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    redirect: "follow",
                    referrer: "no-referrer",
                    body: JSON.stringify(data)
                })/* .then( async function(response){      
                    console.log('post() %o response: %o', path, response)

                    return await response.text().then(function (text) {
                        return text
                      })

                } ).catch((error) => {
                    
                    console.log('post() %o error: %o', path, error)
                    return error
                }) */
    }



    uqrcType(hid) { // in exists setzen, wie mode.SETUP etc
        
        let urltype = window.getURLParameter('type')


        console.log('uqrcType(hid) %o', hid)
        console.log('uqrcType(hid) urltype %o', urltype)

        let ut 
        if( urltype )
             ut = [urltype]
        else
            ut = hid ? [hid] : [
                (this.getRenderDataElement('header', 'id') || '').toLocaleLowerCase()
            ]

        console.log('uqrcType(): ut: %o', ut)

        let types = {
            //BI: typeof ut.find(e => e.match(/^boe$|boehringer|^bi$|header/)) !== 'undefined', // header == nur bei BI?
            AI: typeof ut.find(e => e.match(/aident|a-ident|^ai$/)) !== 'undefined',
            AD: typeof ut.find(e => e.match(/adok|a-dok|^ad$/)) !== 'undefined',
            VB: typeof ut.find(e => e.match(/^vb|digsievb|1stad/)) !== 'undefined',
            WD: typeof ut.find(e => e.match(/whatsdown|^wd$/)) !== 'undefined',
            CDA: typeof ut.find(e => e.match(/^cda$/)) !== 'undefined',
            CDAP: typeof ut.find(e => e.match(/^cdap$/)) !== 'undefined',
            EAG: typeof ut.find(e => e.match(/wdeag|eagwd/)) !== 'undefined',
            SERVICE: 'default',
            USER: 'default'
        }

        if (types.AD) types.SERVICE = 'a-dok'
        if (types.AI) types.SERVICE = 'a-ident'
        if (types.WD) types.SERVICE = 'whatsdown'
        if (types.VB) types.SERVICE = '1stad'
        if (types.EAG) types.SERVICE = 'wdeag'
        if (types.CDA) types.SERVICE = 'cda'
        if (types.CDAP) types.SERVICE = 'cdap'

        console.log('types %o', types)

        return types
    }


    editMode() {
        // => this.mode.EDIT
        //SIEDE SCHNELLWURF VERBanDSBUCH 1602
        let { WD, BI } = this.uqrcType()
        if (WD || BI)
            return true

        if (!this.permid.DOCUMENT) {
            if (this.noChangeRightsRef) this.noChangeRightsRef.show() // TODO
            return false
        }
        return this.idtoken.alive()
    }


    async redirect2login() { // nur a-dok oder vb
        // console.log('redirect2login()')

        //neverused// let { AD, VB, SERVICE } = this.type
        let redirect = 'https://www.my-qr.io/error'
        //neverused// let SERVICE_QUERY = '?service=' + SERVICE + '&qrid=' + this.pid

        //if (AD) redirect = URL.AD_FBAUTH + SERVICE_QUERY + '&rc'
        //if (VB) redirect = URL.VB_FBAUTH + SERVICE_QUERY + '&rc'
        //if (VB) redirect = window.location.href + '?mode=checklist'

        //if (AD)
            redirect = URL.AD_FBAUTH + '?service=a-dok&qrid=' + this.pid + '&user=' + this.getRenderDataElement('text', 'value') + '&idtoken=' + this.idtoken
/* 
        if (VB)
            redirect = URL.DF_WEB_VIEWER_VB + '?service=1stad&qrid=' + this.pid + '&user=' + this.getRenderDataElement('text', 'value') + '&idtoken=' + this.idtoken
  */
        window.location.href = redirect



        //TODO vor dem redirect gibts ein kurzes Aufblinken des normalen uq-viewers

        //let { VIEW, EDIT } = this.mode

        //let DF_FBAUTH_RC_SERVICE = DF_FBAUTH_RC.replace(/%SERVICE%/gi, this.type.SERVICE) 
        //let DF_QUERY_SERVICE_USER = DF_QUERY.replace(/%SERVICE%/gi, this.type.SERVICE) 

        // DF LOGIN
        /* if (VIEW) */
        //window.location.href = URL.DF_WEB_VIEWER_AD + DF_FBAUTH_RC_SERVICE + this.pid

        // DF VIEW
        /* if (EDIT)
            window.location.href = URL.DF_WEB_VIEWER_AD + DF_QUERY_SERVICE_USER + this.pid + "&idtoken=" + this.idtoken */

        // NO REDIRECT on INTRO! switch state/page

        //if (AI || AD) { // todo VB und WD einbauen. bei allen gilt wenn token.alive() und create (exists=0 ?) dann setup
        //if (this.createMode /* INTRO */) { // exists=0 (!!)  , token=1 => mode.SETUP

        /* if (this.idtoken.alive()) { // exists=0, token=1 => mode.SETUP
            // this.permid.DOCUMENT = true // ?? wieso true => für error page...jsonToEdp()....
            return false
        }
        else // INTRO
        { */ // => mode.INTRO , no token

        /* let path = this.type.AD ? 'adintro' : 'aiintro' // todo auch hier VB dazu hier ist der idtoken ungültig, d.h. der createmode redirecte letztlich wieder auf die intropage

        if (LOCALDEV) // => mode.INTRO && local
            window.location.href = window.location.protocol + '//' + window.location.host + "/" + path + "/" + this.pid
        else // => mode.INTRO && (AD||AI) ...deployed @ siede
            window.location.href = "https://" + BASEDOMAIN + ".digsie.de/" + path + "/" + this.pid */
        //}

        //} else { // exists=1
        // todo VB (1stad) case einbauen, der dann zu web.df.... service=1stad führt
        // VB verhält sich hier genau wie AD
        // hier noch die konstanten URL.AD_FBAUTH verwenden
        // if (AD) { // AD VIEW MODE REDIRECT  and  AD LOGIN REDIRECT
        //     if (this.idtoken.alive() /*  "EDIT" AD bei DF */)
        //         window.location.href = "https://web.df.mws-aws.de/?service=a-dok&user=a-dok&qrid=" + this.pid + "&idtoken=" + this.idtoken
        //     else /*  LOGIN AD bei DF */
        //         window.location.href = "https://web.df.mws-aws.de/login_fbauth_rc/?service=a-dok&qrid=" + this.pid
        // }
        //}
        //}
        // todo 1602: wenn in json ein element AUTH VORHANDEN IST, DANN WIRD ZUM CHILID  "AUTHURL" dann wird hier zur authurl weitergeleitet
        // alternativ steht im JSON ein zu vewrendetnder defaultname (z.b. system_wdwebui) drin, der an den server weitergereicht wird
        // der server weiss dann zu diesem user das passsowrt (oer er kennt den user nicht, dann weist er den request ab)
        // und noch mehr in der zukunft müssen wir das webhook-konzept implementier, wo der server aufgrund der id den passenden webhook-api-key auswählt, wie genau müssen wir überlegen


    }


    /*   getPermIDs(e){
     
          this.permid.CHILD = e.childpermid
          this.permid.DOCUMENT = e.permid
    
          if(e.success())
            try {
              this.permid.PARENT = e.response.success.object[0]._
              this.permid.CHILD = e.response.success.object[0]._
            } catch (err) {}
    
        
        if (e.response && e.response.data) 
            this.permid.PARENT = e.response.data.permId
      
    
        // brute force/safety für ggf. falsch im resolver konfigurierte Etiketten (a-ident change 25.10.2019)
        if (this.template === 'ai') {
          this.permid.PARENT = 'dBL3MZWMub'
        }
      } */


    noRigthsPage(extension) {
        // besser in json/template auslagern und per /get abholen ? 
        // if ((!this.createMode && !this.permid.DOCUMENT) || exists.code === 500) {
        this.noEditRights = true
        let { AI, AD, BI, WD, VB, CDA, CDAP } = this.type



        let header = {
            id: extension,
            name: 'unset'
        }

        if (AI) header = { id: 'ai', name: 'Wichtig! Immer dabei, mit A-Ident.' }
        if (AD) header = { id: 'ad', name: 'Immer dabei, mit A-Dok.' }
        if (BI) header = { id: 'bi', name: 'Boehringer' }
        if (WD) header = { id: 'wd', name: 'WhatsDown' }
        if (VB) header = { id: 'vb', name: '1stad' }
        if (CDA) header = { id: 'cda', name: 'Desinfektions-Assistent' }
        if (CDAP) header = { id: 'cdap', name: 'Corona-Anwesenheitsliste' }

        this.renderData = [
            {
                "id": header.id,
                "name": header.name,
                "type": "header"
            }, {
                "id": "intro",
                "name": "",
                "text": "",
                "aident": "Keine Änderungsrechte zur ID ",
                "type": "introduction"
            }, {
                "id": "noaccess",
                "name": "",
                "text": "Die verwendete Telefonnummer gehört nicht zu diesem Etikett. ",
                "label": "Hinweis: ",
                "css": { /* "border": "1px solid gray", "backgroundColor": "white", "color": "#3f3f3f" */ },
                "type": "textbox"
            },
            {
                "type": "html",
                "html": "<a href='https://my-qr.io/" + header.id + "/" + this.pid + "' style='color:#4c4c4c; margin-top: 1vh;display: block;font-weight: normal;text-align: center; padding: 30px; border-radius: 100vh; background-color: hsla(0, 0%, 82%, 1);'>Mit zugehöriger Telefonnummer einloggen</a>"
            }/* ,{ //debugging
                "type":"postmessage2edp",
                "subject":"Betreff %deviceName%",
                "body":"Benutzer %cdap00% hat sich in die Liste mit der Telefonnummer %cdap01% eingetragen"
            } */
        ]
        // }
    }


    async exists() {
        let response = await this.post('/exists')
        console.log('exists() post response %o',response)
        return json(this, response)
    }


    showError(e) {
        if (!document.getElementById('error')) return null

        let innterHTML = document.getElementById('error').innerHTML
        innterHTML += innterHTML.length > 0 ? '<br/>' : ''
        document.getElementById('error').style.padding = '10px'
        document.getElementById('error').innerHTML = innterHTML + e
    }


    hideError() {
        if (!document.getElementById('error')) return null
        document.getElementById('error').style.display = 'none'
    }


    async jsonToEdp(mode, permid, phonenumber, type) {

            let edpResponse = {}
            let post2edp = !(this.getRenderDataElement('header', 'post2edp') === 'false') // default wegen backwards compatibility true

            if(post2edp){
                let opts = {
                    permid: permid,
                    data: JSON.stringify(this.renderData),
                    phone: phonenumber,
                    mode: mode,
                    type: type || this.edpPrefix,
                    qrid: this.pid,
                    pid: this.pid
                }
                //console.log('jsonToEdp() TRY posting /set with options: %o', opts)
                edpResponse = await this.post('/set', opts)
            }
            return edpResponse
    }


    sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);
      }


    async mwsCreateEDPObject() {
        const self = this
        let deviceName = document.querySelector('#deviceName')

        await this.post('/create', {
            permid: this.permid.PARENT,
            title: (deviceName ? deviceName.value : this.pid),
            flavor: this.type.SERVICE
        })
            .then(function (response) {

                response.text().then(
                    async function (response) {


                        let _json = {}
                        try {
                            _json = JSON.parse(response)
                        } catch (e) {
                            self.showError('mwsCreateEDPObject: ' + e)
                            return false
                        }


                        if (_json.code === 500)
                            self.showError('mwsCreateEDPObject 500: ' + _json.response)

                        if (_json.response && _json.response.failure)
                            self.showError('mwsCreateEDPObject failure: ' + JSON.stringify(_json.response.failure))


                        let responsedSuccess = _json.response && _json.response.success
                        if (responsedSuccess) {
                            self.displayPreview = true
                            self.permid.CHILD = _json.response.success['obj-permid'][0]

                            let urluser = window.getURLParameter('user')
                            let phonenumber = urluser ? urluser.replace(" ", "+") : ''

                            // console.log('create phonenumber %o ', phonenumber)

                            // json zum server schicken und zwar genau dann, wenn im JSON das edpPrefix gesetzt ist wird dann z.b. zu $aident_codeid oder zu $wdconfig_codeid
                            // console.log('create self edpPrefix %o', self.edpPrefix)
                            if (self.edpPrefix) {
                                if(self.postmessage2edp){
                                    await self.postJsonMessages2EDP(self.postmessage2edp)
                                }
                                   

                                /* if (!self.mode.CHECKLIST) {
                                    // console.log('create mode CHECKLIST')
                                    await self.jsonToEdp('new', self.permid.CHILD, phonenumber)

                                } else { */

                                    /* let photosUploaded =  */await self.uploadPhotos()
                                   
                                    // console.log('self.renderData uploadPhotos() %o', self.renderData)
                                    //await fetch(self.http + self.uqrcServer + '/set?mode=new&pid=' + self.pid + '&data=' + encodeURI(JSON.stringify(self.renderData)) + '&permid=' + self.permid.CHILD + '&token=' + t /*(self.idtoken || self.apiToken) */ + '&type=' + self.edpPrefix )

                                    /* await self.post('/set',
                                      JSON.stringify({
                                        pid: self.pid,
                                        permid: self.permid.CHILD, 
                                        token: t,
                                        data: JSON.stringify(self.renderData),
                                        mode: 'new',
                                        type: self.edpPrefix
                                      })
                                    ) */

                                    //console.log('mwsCreateEDPObject: /create jsonToEdp("new", self.permid.CHILD, phonenumber)')

                                    //if( photosUploaded )             jsonToEdp(mode, permid, phonenumber, type, notification)
                                    let phone = getJwt().phone_number
                                    /* let setResponse =  */await self.jsonToEdp('new', self.permid.CHILD, phone/* phonenumber */)
                                    
                                    //console.log('setResponse: %o', setResponse)

                                    //TODO wenn type addfolder exisitert, dann alle addfolder per /setfolder im EDP anlegen
                                    // serverseitig besser


                                    // TEMP vorrübergehend auskommentiert (17.9.19)
                                    /* if (!window.webkit)
                                      window.location.reload() */
                                //}
                                // console.log('AH2105 self.headerid: %o', self.type.Service)
                                


                                if (self.type.Service==='CDA') {
                                    await self.post('/welcome', {
                                        permid: self.permid.PARENT,
                                        flavor: self.type.SERVICE
                                    })
                                }
                                    
                            } else {
                                // WD zweig, messag esoll in sjson
                                await self.post('/welcome', {
                                    permid: self.permid.PARENT,
                                    flavor: self.type.SERVICE

                                })
                            }//

                            //TODO if json-elementsendmsg dann msg senden (ähnlich creatsubfolders)

                            // console.log('790 self.headerid: %o', self.headerid)

                            if (self.type.WD /* headerid.match(/whatsdown/) */ /* && !window.webkit && !isAndroid */) {
                                // console.log('794')

                                let pn = encodeURI(window.getURLParameter('user'))
                                window.location.href = 'https://web.whatsdown.com/?user=' + pn + "&idtoken=" + self.idtoken
                            }

                            if (self.type.AD || self.type.AI || self.type.VB || self.type.CDAP) {
//                                window.location.href = window.location.href + '&display=preview'
                                window.location.href = window.location.href + '&display=preview&from=setup'
                                // URL.DF_WEB_VIEWER_AD + '?service=a-dok&user=a-dok&qrid=' + self.pid + (self.type.AD /* .headerid === 'adok'  */ ? '&idtoken=' + self.idtoken : '')
                                //self.forceUpdate()
                            }
                        } else {

                        }
                        // response.success.obj-permid[0]
                    })
            });
        return true
        // TODO: ungültiger token -> meldung
    }



    savePhotoUrl(r, k) {
        this.renderData.map((e, i) => {
            if (e.id !== k)
                return null

            if ((e.type != null) && e.type.match(/photo|document/)) {
                this.renderData[i]['file'] = r.response.success.attachment_url[0]
            }
            return true
        })
    }



    async uploadPhotos() {
        console.log('uploadPhotos() ')
        let t = await this.getToken()

        for (var k in this.imageRefs) {
            console.log('uploadPhotos() for (var k in this.imageRefs) {')

            let fileType = this.fileTypes[k] ? this.fileTypes[k] : 'noFileType'
            let fileName = 'file.' + fileType

            var formData = new FormData()
            formData.append('qrid', this.pid)
            formData.append('message', k)
            formData.append('token', t)
            formData.append('filetype', fileType)
            formData.append('file', this.imageRefs[k], fileName)

            // each photo
            const response = await fetch(
                this.helper + '/message',
                {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    body: formData
                }
            )

            try {
                const result = await response.json()
                if (result.response.success.attachment_url) {
                    this.savePhotoUrl(result, k)
                }
            } catch (error) {
                console.error('uploadPhotos() catch() error: %s', error)
            }
        }

        return true
    }


    test_replaceTextVariablesByValues(){
        let str1 = JSON.stringify({"subject":"Betreff _LISTENNAME_","body":"CHECKOUT: %cdap00% (Tel. %cdap01%) hat sich aus der Liste ausgetragen"})
        return {
            test1: this.replaceTextVariablesByValues(str1)
        }
    }


    test_setCookie(){
        this.setCookie( 'logoutmessage_TEST1', 'NN_TEST1', 3/24, 'afterMidnight')
        this.setCookie( 'logoutmessage_TEST2', 'NN_TEST2,NN', 3/24, 'afterMidnight')
        this.setCookie( 'logoutmessage_TEST3', 'NN_TEST3;NN', 3/24, 'afterMidnight')
        
        return {
            test1: this.getCookie('logoutmessage_TEST1'),
            test2: this.getCookie('logoutmessage_TEST2'),
            test3: this.getCookie('logoutmessage_TEST3')
        }
    }


    replaceTextVariablesByValues(str = '' ){

        var strOut = ''
        var strArr = str.split('<')
        for (let i = 0; i < strArr.length; i++) {
            var str_i = strArr[i]

            var isOptional = strArr[i].indexOf('>') >= 0 && strArr.length > 1
            let replacedString = str_i
            str_i.split(' ').forEach( (word) => {

                let matched =  word.match(/%.*%/)
                if( matched ){

                    let variableName = word.match(/%.*%/)[0]
                    let elementID = variableName.substring(1,variableName.length-1)

                        let val
                        switch (elementID) {
                            case 'objectTitle':
                                val = this.getRenderDataElement('urlparams', 'title')
                                break;
                        
                            case 'objectTime':
                                val = this.getRenderDataElement('urlparams', 'time')
                                break;
                        
                            default:
                                val = this.getRenderDataElement( null, 'value', elementID)
                                break;
                        }
                    
                    if( val ){
                        replacedString = replacedString.replace( variableName, val)
                        replacedString = isOptional ? replacedString.replace( '>', '') : replacedString
                    } else {
                        replacedString = replacedString.replace( variableName, '') 
                        replacedString = isOptional ? replacedString.substring(replacedString.indexOf('>')+1) : replacedString
                    }
                }
            })

            strOut += replacedString
        }
        return strOut
    }


    async postJsonMessages2EDP(msg = {}){

        let mbody = msg.body
        let subject = msg.subject

        mbody = mbody ? this.replaceTextVariablesByValues(mbody) : ''
        subject = subject ? this.replaceTextVariablesByValues(subject) : ''

        return await this.postMessage( subject + ' ' + mbody)
    }


    test_postMessage(){
        let str1 = ("NN MESSAGE POST TEST")
        return {
            test1: this.postMessage(str1)
        }
    }


    async postMessage(m){
        
        let t = await this.getToken()

        let data = {
            qrid : this.pid,
            message : m,
            token : t
        }

        const self = this

        return await fetch(this.helper + '/message', {
            method: 'POST',
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(data)
        })/* .then(response => response.json()) */
        .then(async result => {

            await result.json().then(
                async function (response) {
                    console.log('SUCCESS JSON:', response);

                    
                })

            return result
        })
        .catch(error => {
            console.error('Error:', error);
            self.showError('Ups, irgendwas ist schief gelaufen. Bitte probiere es nochmal oder kontaktiere den Support.')
            return error
        });
    }


    // in send() aufgerufen
    async inspectRequiredFields() {
        this.inspectedRequiredFields = true

        let done = true
        let elem = null
        let undone = {}

        let ri = this.inputs.concat(this.rows)
        for (var key in ri) {
            let k = key * 1 // str2int
            let row = ri[k]
            let req = row.required === true ||
                    row.required === 'true' ||
                    row.required === '1'


            let val = row.state.value || row.state.choice
            val = val || val === 0 // 0 erlaubt, '' => false

            if (done && req && !val) { // 1. leeres required feld
                done = false
                elem = row.refs.thisElement
            }

            if (req && !val) { // add incomlete section + highlight 
                undone[row.section] = this.sections[row.section]
                row.setState({ redBorder: true })
            }
        }


        

        // öffne nötige sektionen 
        if (!done && elem) {
            Object.keys(undone).forEach(function (key) {
                if (this[key] && this[key].state.deg === 0)
                    this[key].toggleSection()
            }, undone)

            elem.focus()

            return false
        } else
            return true
    }


    async save_intermediate() {
        this.HTMLtoJSON('intermediate')

        if (window.webkit) {
            await this.savetoappIntermediate(this.renderData)
        }
    }


    unfinishedModal() {
        return this.ai ? false : !this.rows.every(function (row) {
            let o = row.state.isOpen
            let c = row.state.choice
            let s = row.modal.savedContent
            let m = row.modal.state.msg
            if ((!m | !s) && o && c === 0)
                return false
            else
                return true
        })
    }


    getOpenModal() {
        let mod
        this.rows.every(function (row) {
            mod = row.modal
            return !row.state.isOpen
        })
        return mod
    }


    grayOutButton(id) {
        let elem
        if (typeof id === 'string')
            elem = document.getElementById(id)
        else
            throw new Error('ID parameter not a string')

        if (elem)
            elem.style.backgroundImage = 'linear-gradient(to right, #b9b9b9 0%, #dadada 100%)'
        else
            throw new Error('Element ID not found.')
    }


    async send() {
        
        let { SETUP, EDIT, CHECKLIST } = this.mode
        let { CDAP } = this.type

        let requiredFieldsDone = await this.inspectRequiredFields()
        if (!requiredFieldsDone)
            return

        this.grayOutButton('submit')
        this.HTMLtoJSON()

        if(CDAP && CHECKLIST){
            this.setCookie('login_'+this.pid, new Date().toLocaleString().replace(',', ' '), 3/24, 'afterMidnight' )
            this.setCookie('showLoginAgain_'+this.pid, 'true', 2 )

            if(!this.cookiesAllowed){
                // delete personal data
                // save logoutcookie
            }
        }


        if (window.webkit && !window.location.href.includes('setup')) {
            // wenn es sich um eine checkliste handelt (pfad ist nicht setup) dann wird in der IOS-Version der spezielle checklistenpost verwendet, der das JSON parsed und ggf. comments/images hochlädt (bisher nur IOS)
            // in app nicht mehr notwendig (16-06-2020):
            // await this.savetoapp(this.pid, this.renderData)
        }


        if (SETUP /* && !this.mode.EXISTS *//* (AI || WD || AD || BI) && !this.editMode() */){
            await this.mwsCreateEDPObject()
        }
        else{
            //if (/* this.headerid.match(/whatsdown|aident|adok/) */ (AI || WD || AD || BI) && this.editMode()) {

            // 1. display fotos
            // 2. upload only if changed
            /* let photosUploaded =  */await this.uploadPhotos()
            // await fetch(this.helper + '/set?mode=update&pid=' + this.pid + '&data=' + encodeURI(JSON.stringify(this.renderData)) + '&permid=' + /* this.permid.CHILD */ this.permid.DOCUMENT + '&token=' + this.idtoken + '&type=' + this.edpPrefix /*(self.idtoken || self.apiToken) */ )

            //if(photosUploaded){
                //TODO: REFACTOR !!! parameter stezen und jsonToEDP nur ein mal aufrufen (NN)
                let fileMode = 'update' //this.permid.PROTOCOL ? 'update' : 'new'
                // console.log('fileMode %o', fileMode)
                if (CHECKLIST) { // TODO: UPDATE oder NEW (gibt es $protocol schon?)
                    
                    let perm = this.permid.PROTOCOL ? this.permid.PROTOCOL : this.permid.CHILD /* this.permid.DOCUMENT ??? */
                    await this.jsonToEdp(fileMode, perm, this.user, 'protocol' /* this.permid.DOCUMENT */)
                    await this.postJsonMessages2EDP(this.postmessage2edp)
                    
                }

                // let { AI, WD, AD, BI } = this.uqrcType() // SIEDE SCHNELLWURF VERBANDSBUCH

                // SIEDE SCHNELLWURF
                let perm = this.type.BI || this.type.WD ? this.permid.CHILD : this.permid.DOCUMENT
                let prefix = this.type.WD ? 'protocol' : this.edpPrefix

                if (EDIT)
                    await this.jsonToEdp(fileMode, perm, this.user, prefix )

		//}

                if (this.type.WD) {
                    let user = encodeURI(window.getURLParameter('user'))
                    window.location.href = URL.WEB_WHATSDOWN + '?user=' + user + "&idtoken=" + this.idtoken
                }
              
                this.displayPreview = true //refactored
                // this.forceUpdate()
                window.location.href = window.location.href + '&display=preview'
        }

            // TODO mwsCreateEDPObject muss Permid/DVID zurückgeben {id}, die dann diesem savetoserver mitgegeben wird, damit /set in server.js den post/documents/{id}/file aufrufen kann
            // TODO: wegen überholsituation auf dem server (set wird ausgeführt, bevor der create fertig ist) steht die permid.CHILD nicht fest und schlägt fehl    
            // await this.savetoserver(this.pid, this.renderData) 

            /* if (window.webkit)
                window.WebViewClose() */

            /* if( this.displayPreview )
            // refresh to show WD-Setup-Foto-Upload
            this.forceUpdate() */

            // TODO: change render type (display data)
            /* if (!window.webkit)
            window.location.reload() */
        //}
    }


    async savetoserver(pid, renderData) {
        await fetch(this.helper + '/set?pid=' + pid + '&data=' + encodeURI(JSON.stringify(renderData)) + '&permid=' + this.permid.CHILD + '&token=' + this.idtoken + '&type=' + this.edpPrefix)
    }


    async savetoapp(pid, renderData) {
        await window.writeUQRSjson(JSON.stringify(renderData), 'postandsafe')
    }


    async savetoappIntermediate(renderData) {
        await window.writeUQRSjson(JSON.stringify(renderData), 'safe')
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length).replace(SEMICOLON_RX, ';');
          }
        }
        return "";
      }

    setCookie(cname, cvalue, exdays = 3, afterMidnight = false ) {
        cvalue = encodeURIComponent(cvalue.replace(/;/g, SEMICOLON))
        var d = new Date();

        if(afterMidnight)
            d.setHours(23,59,59,0)

        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";"; //path=/ // <= path leads to SAFARI logout problem, cookie wont overwrite
    }

    readValueFromCookie(id, cookieName){
        // console.log('readValueFromCookie( %o, %o )', id, cookieName)
        if(!cookieName || !id)
            return

        // console.log('this.getCookie(cookieName) %o', this.getCookie(cookieName))

        

        try {
            let cookieValue = JSON.parse( this.getCookie(cookieName) )
            return cookieValue[id]
        } catch (err) {
            return ''
        }
    }

    saveInputsToCookie(){
        if(!this.cookie.name || !this.mode.CHECKLIST)
            return false

        for( let key in this.cookie.value ){ 
            this.cookie.complete[key] = this.replaceTextVariablesByValues(this.cookie.value[key])
        }

        if(this.cookie.complete){

            if(this.cookiesAllowed)
                this.setCookie(this.cookie.name, JSON.stringify( this.cookie.complete ))
            else
                this.setCookie(this.cookie.name, '', 0)

            let {body, subject} = this.logoutmessage
            if(body || subject)
                this.setCookie( 'logoutmessage_'+this.pid, this.replaceTextVariablesByValues(JSON.stringify(this.logoutmessage)), 3/24, 'afterMidnight')
        }

        // console.log('STRING FUNCTION: %o', this.replaceTextVariablesByValues('%cdap01% <In Begleitung von %cdap00%> ba %cdap01% <In Begleitung von %cdap00%> ba'))
    }

  
    inputKeyUpHandler(e) { 

        this.checklistFinished()
        // mark required INPUTS
        if (this.inspectedRequiredFields && e && e.target.tagName === 'INPUT') {
            let el = e.target
            el.style.border = !el.value && el.required ? '2px solid red' : ''
        }
        
        // mark required SELECTS
        if (this.inspectedRequiredFields && e && e.target.tagName === 'SELECT') {
            let el = e.target
            el.style.border = !el.value && el.dataset.required ? '2px solid red' : ''
        }
    }


    // speichern-button [on|off]
    checklistFinished() {
        setTimeout(function () { // auf state-update warten
            if (!this.initialSubmitState) {

                let val
                this.inputs.concat(this.rows).every((e) => {
                    val = e.state.value || e.state.choice

                    console.log(val)

                    if (typeof val === 'string')
                        val = val.trim().length > 0
                    else
                        val = val || val === 0 // 0 erlaubt            

                    return val
                })
                // speichern-btn aktivieren
                this.setState({ showSubmit: val })
            }
            // immer speichern
            return this.save_intermediate()
        }.bind(this), 10)
    }



    HTMLtoJSON(mode) {
        var imax = 0
        var urlParamsIndex = -1
        this.renderData.map((e, i) => {
            imax = i+1
            
            let val = e.value ? e.value.replace('"', '\\"') : e.value
            let id = e.id
            let type = e.type
            let elem = document.getElementById(id)

            if(Boolean(type.match(/urlparams/)))
                urlParamsIndex = i
       
            // return if value is set (item checked)
            if (elem && typeof elem.value !== 'undefined' && elem.value !== null) {
                val = elem.value + ''
            }/* else {
        if (this.renderData[i]['value'])
          delete this.renderData[i]['value']
         
        return null
      } */

            // write ['value'] to renderData array
            if ((type != null) && type.match(/select|text|number|email|checkit|urlparams|datetime-local/)) {
                this.renderData[i]['value'] = val //todo:encodeURI

                let status = '(status unknown)'
                switch (val) {
                    case '0':
                        status = 'ok'
                        break;
                    case '1':
                        status = 'not ok'
                        break;
                    case '2':
                        status = 'unavailable'
                        break;
                    default:
                        status = 'unset'
                        break;
                }

                if (type != null && type.match(/checkit/)) {

                    // text area 
                    let txt = this.rows[i].modal.state.msg
                    //txt = txt === '' || typeof txt === 'undefined' ? '' : ': ' + txt

                    this.renderData[i]['msg'] = mode === 'intermediate' ? txt : e.name + ' - ' + status + ': ' + txt
                    this.renderData[i]['datum'] = this.rows[i].modal.state.datum
                    if (this.images[i])
                        this.renderData[i]['userImage'] = this.images[i]
                }

                if (type != null && type.match(/checkit/)) console.log("this.renderData[i]['value'] %o", this.renderData[i]['value'])
                if (type != null && type.match(/checkit/)) console.log("this.renderData %o", this.renderData)
            }

            // console.log('HTMLtoJSON saveInputsToCookie()')
            this.saveInputsToCookie()

            return null
        })


        let searchParams = new URLSearchParams(window.location.search)
        let msie = window.navigator.userAgent.indexOf("MSIE ") > -1
        if ( !msie ) {
            imax = urlParamsIndex > -1 ? urlParamsIndex : imax
            this.renderData[imax] = {}

            this.renderData[imax]['type'] = 'urlparams'
            this.renderData[imax]['title'] = this.displayTitle
            this.renderData[imax]['time'] = new Date().toLocaleString()
            this.renderData[imax]['last_update'] = this.last_update
            this.renderData[imax]['pid'] = this.pid //zusätzlich zu qrid im query
            //todo userid aus idtoken lesen und speichern
            //todo useragent speichern

            // console.log(this.renderData)

            var self = this
            searchParams.forEach(function(value, key) {
                let k = key === 'type' ? 'typeUrl' : key // "type":"urlparams" schon belegt
                self.renderData[imax][k] = value
            })

            //timestamp checklistenjson:
            // last_update: aus dem edp

            return null
        }
        
    }

    setImagePath(p, i) {
        this.images[i] = p

        this.save_intermediate()
    }

    /* toggledCollapse(){ 
      if(this.unfinishedModal() ){
        this.getOpenModal().notes['noText'].show()
        return false
      }else{
        return true
      }
    } */

    validate() {
        this.validated = true
    }

    setValidated(b) {
        this.validated = b
        this.forceUpdate()
    }

    JSONtoHTML(json) {
        this.hideLoader()
        // console.log('JSONtoHTML json: %o', json)

        var activeSection = ''
        var collapseSection = false
        var re = new RegExp(' ', 'g');

        let startElementInJSON = false
        let createdHTML = json.map((e, i) => {

            let id = e.id
            let name = e.name
            let type = e.type
            let value = e.value ? e.value.replace('\\"', '"') : e.value
            let allwaysOn = false /* this.overrideValidatedAccess !== null ? this.overrideValidatedAccess : */  // e.on === 'true'
            let required = e.required === '1'

            /* if( this.displayPreview && type !== 'header' )
              return null */

            type = FILTER_TYPES.includes(type) ? 'FILTER' : type

            switch (type) {

                case 'validatedAccess':
                    // validatedAccess wird in type0start gecheckt

                    /* this.rundgangStartClass = e.validatedAccess.match(/QR/) ? 'redBtn' : 'greenBtn'  */

                    break;



                case 'header': //NN-notiz: nicht gut, refactorn
                    this.headerid = id
                    this.type = this.uqrcType(id)

                    this.edpPrefix = e.edpPrefix
                    this.InstanceName = name
                    this.ai = id === 'aident' || id === 'adok'

                    if (id !== 'whatsdown')
                        document.querySelector('#root').style.marginTop = '4em'

                    if (id === 'aident' ||
                        id === 'adok' ||
                        id === 'whatsdown')
                        this.validated = true

                    /* this.setBrowserPageTitle(id)
                    this.setFavicon(id) */

                    return <Header name={name} id={id} key={i} pid={this.pid} isAIdent={this.ai} app={this}/>



                case 'margin':
                    return <div key={i} className='margin'></div>


                // FEATURE SETCOOKIE
                case 'setcookie':
                    try {
                        this.cookie.name = e.name
                        this.cookie.value = JSON.parse( e.value.replace(/'/g, "\"") )
                    } catch (err) {
                        this.cookie.name = null
                        this.cookie.value = {}
                    }

                    return null

                // FEATURE SHOWTIME
                case 'datetime':
                    return <ShowTime app={this} key={i}/>


                // FEATURE INTRODUCTION
                case 'introduction':
                    let intro = this.renderData ? e.post : e.text
                    let isAIdent = false

                    if (e.aident) {
                        intro = e.aident
                        isAIdent = true
                    }

                    return <Introduction key={i} name={name} e={e} intro={intro} pid={this.pid} guide={e.guide} headerID={this.headerid} isAIdent={isAIdent} />



                case 'textbox':
                    // nur bei eingabe anzeigen? => mode.VIEW bzw !(SETUP || EDIT || CHECKLIST)
                    // label / name
                    return /* this.renderSavedInstance  */this.mode.VIEW ? null : <TextBox key={i} message={e.text} css={e.css} />

                case 'wdintroduction':
                    let wdintro = this.renderData ? e.post : e.text

                    return <WDIntroduction key={i} name={name} intro={wdintro} pid={this.pid} />


                case 'postmessage2edp':
                    this.postmessage2edp = e

                    return null


                case 'createsmsnotification':
                    // serverside only
                    return null


                case 'logoutmessage':
                        this.logoutmessage.subject = e.subject
                        this.logoutmessage.body = e.body

                    return null

                case 'start':
                    // return null //this.validated = true
                    if (this.mode.VIEW)
                        return this.validate()

                    startElementInJSON = true

                    if ('validatedAccess' in e)
                        this.validatedAccess = e.validatedAccess.match(/QR/)

                    return <StartButton jsonData={e} parent={this} key={i} />



                case 'getImage_':
                    return <div key={i} id='getImage' onClick={
                        () => {
                            //this.getQRCode_('2342315')
                        }
                    }>{'getImage'}</div>

            
                case 'iconSelector':
                    return <IconSelector e={e}/>


                case 'submit':
                    return /* !this.renderSavedInstance */ (this.mode.EDIT || this.mode.SETUP || this.mode.CHECKLIST) && this.validated ?
                        <Submit
                            key={i}
                            active={this.state.showSubmit}
                            name={name}
                            hid={this.headerid}
                            submit={() => this.send()}
                            app={this}
                        />
                        :
                        null



                case 'submit_intermediate':
                    return (
                        <div key={i} id="submit_intermediate" className='submit' onClick={() => this.save_intermediate()}>!Zwischenspeichern!
                        </div>
                    )



                case 'html':
                    return <div key={i} className={activeSection + ' userHTML'} dangerouslySetInnerHTML={{ __html: e.html }} data-display={collapseSection ? 'hide' : 'show'} ></div>

                case 'checkit': // console.log(' HTMLtoJSON() checklist')
                    if (!startElementInJSON)
                        this.validate()

                    this.firstElement = this.firstElement || i

                    // TODO render mode einbauen
                    /* return this.renderSavedInstance? null : ... */
                    return <CheckListItem
                        disabled={!this.validated && !allwaysOn}
                        index={i}
                        key={i}
                        e={e}
                        parent={this}
                        section={activeSection}
                        collapse={collapseSection}
                        ref={c => this.rows[i] = c}
                        ai={this.ai}
                    />



                case 'section':
                    activeSection = name.replace(re, '_')
                    collapseSection = e.collapse === 'true' || parseInt(e.collapse) === 1

                    if (!startElementInJSON)
                        this.validate()

                    if (e.isHeader && e.isHeader === 'true')
                        this.headerElements.push(name)

                    return <Section key={i}
                        e={e}
                        app={this}
                        name={name}
                        disabled={!this.validated && !allwaysOn}
                        section={activeSection}
                        toggledCollapse={this.toggledCollapse}
                        ref={c => this.sections[name] = c} />



                case 'photo':
                    let file = ''
                    if(e.file && e.file.indexOf('http') === 0)
                        file = e.file
                    else
                        file = this.helper + '/img?f=' + e.file

                    return this.mode.VIEW || this.mode.SETUP ?
                        e.file ? <PhotoDisplay key={i} name={e.post || e.name} file={file} /> : null
                        :
                        <PhotoAdd e={e} key={i} app={this} setImageRef={this.setImageRef} resetImageRef={this.resetImageRef} file={file} />




                case 'document':
                    let doc = e.file ? this.helper + '/pdf?f=' + e.file : ''
                    return /* this.renderSavedInstance  */ this.mode.VIEW ?
                        e.file ? <DocumentDisplay key={i} name={e.post || e.name} file={doc} /> : null
                        :
                        <DocumentAdd e={e} key={i} app={this} setImageRef={this.setImageRef} resetImageRef={this.resetImageRef} file={doc} />



                case 'text':
                case 'number':
                case 'select':
                case 'datetime-local':
                case 'email':
                    // TODO:
                    // LÖSCHEN - NUR DEBUGGING !!!
                    /* if(i%2)
                     type = 'datetime-local' */

                    //type = 'select'
                    //e.options = [{"name":"Bitte wählen Sie ein Bundesland...","id":""},{"id":"BW","name":"Baden-Württemberg"},{"id":"BY","name":"Bayern"},{"id":"BE","name":"Berlin"},{"id":"BB","name":"Brandenburg"},{"id":"HB","name":"Bremen"},{"id":"HH","name":"Hamburg"},{"id":"HE","name":"Hessen"},{"id":"MV","name":"Mecklenburg-Vorpommern"},{"id":"NI","name":"Niedersachsen"},{"id":"NW","name":"Nordrhein-Westfalen"},{"id":"RP","name":"Rheinland-Pfalz"},{"id":"SL","name":"Saarland"},{"id":"SN","name":"Sachsen"},{"id":"ST","name":"Sachsen-Anhalt"},{"id":"SH","name":"Schleswig-Holstein"},{"id":"TH","name":"Thüringen"}]

                    if (!startElementInJSON)
                        this.validate()
                    // weitere input types?

                    if(!value && e.read_from_cookie)
                        value = this.readValueFromCookie(id, e.read_from_cookie)

                    
                    // console.log('READ COOKIE value: %o', value)

                    // let isUQRCSetup = window.location.pathname.split('/')[1].includes('setup')

                    return value || !this.mode.VIEW /* !this.renderSavedInstance */ ?
                        <Input
                            app={this}

                            multiline={e.line === 'multi'}

                            disabled={!this.validated}  // TODO: validation prozess für UQRC setup unpassend
                            options={e.options}
                            section={activeSection}
                            key={i}
                            id={id}
                            name={name}
                            value={value}
                            type={type}
                            saveCurrent={this.HTMLtoJSON}
                            onKeyUp={this.inputKeyUpHandler}
                            required={required}

                            ref={c => this.inputs[i] = c} />

                        :
                        null

                case 'FILTER':
                    return null

                default:
                    return <UnknownElement type={type} name={name} key={i}/>
            }
            return null
        })

        return createdHTML
    }


    activateVerifiedBtn() {
        this.validated = true

        this.startButton.classList.toggle("redBtn")
        this.startButton.classList.toggle("greenBtn")
        this.forceUpdate()
    }


    async getQRCode(id) {

        let res = '000000'
        if (window.webkit) {
            res = await window.OpenCamera(id, 'qrcode')
            res = decodeURI(res)
        }

        if (res.includes(this.pid) || !window.webkit) // TEMP 13.9. auskommentiert
            this.activateVerifiedBtn()
        else {
            if (this.LocationNoteRef != null) {
                this.LocationNoteRef.show()
            } else
                log('LocationNoteRef undefined')
        }
    }


    async getSetupImage(id, mode) {

        //!isAndroid: klicked Foto aufnehmen ->  in: getSetupImage()'

        // TODO : 'imageData' is assigned a value but never used 
        // let imageData //= "https://vignette.wikia.nocookie.net/asterix/images/9/9f/Asterix%2C_Obelix_%26_Dogmatix.png/revision/latest?cb=20110324122229"

        if (window.webkit) {
      //!isAndroid: klicked Foto aufnehmen ->  in: getSetupImage() ... window.webkit = true '
      /* imageData =  */await window.OpenCamera(id, mode)

            /* imageData += '&srts=' + new Date().getTime() */
        }
    }



    render() {

        
        
    
        //SAFARI COOKIE DEBUGGING
        /* var d = new Date();
        d.setTime(d.getTime() + (1*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();

        this.setCookie('log', '', 1)

        this.setCookie('login', new Date().toLocaleString(), 3/24 )
            

        return <div>cookie:  {this.getCookie('login')}</div>
 */


        // console.log('render App.js - types: %o', this.type)
        // debugging
        /* if(this.renderData)
            this.JSONtoHTML(this.renderData) */

        if (!this.pid || !this.template)
            return <Error meta={META} app={this} />


        if (this.skipFirstRender)
            return this.skipFirstRender = false


        let { VB, WD, CDAP } = this.type
        let { INTRO, MENU, VIEW, SETUP_SUCCESS, LOGGED_IN } = this.mode

        if(LOGGED_IN && !SETUP_SUCCESS)
            return <CdapLogout app={this}/> // <div>LOGGED IN SINCE {this.getCookie('login')} - SHOW LOGOUT BUTTON </div>

        let showMenu = MENU && (VB || WD || CDAP) && VIEW
        if (showMenu || INTRO)
            return <Start url={URL} app={this} menu={showMenu} />


        if (SETUP_SUCCESS)
            return <SetupSuccess url={URL} meta={META} app={this} />


        if (this.renderData) {
            return (
                <DocumentMeta {...META} >
                    <LocalDevIcon />

                    {this.JSONtoHTML(this.renderData)}
                    <NewProtocol app={this} />
                    <Edit url={URL} app={this} />

                    {this.LocationNoteElem}
                    {this.NoChangeRights}
                </DocumentMeta>
            )

        }

        return null
    }
}

export default App

