import React, { Component } from 'react'
import editIcon from './common/svg/edit2.svg'

class Edit extends Component {

    render() {
        let { app, url } = this.props
        let { AI } = app.type
        let { VIEW } = app.mode

        // ÄNDERN-BUTTON NUR BEI AI (mit AH 2020-04-28)
        if( !AI )
            return null

        return ( app.noEditRights || VIEW ? 
            <div className='changeAI'>
                <div>
                    <a href={url.AI_FBAUTH + '?service=a-ident&qrid=' + app.pid} className='changeAIurl' >
                        <img src={editIcon} className='editIcon' alt='edit' />
                    Mein A-Ident bearbeiten
                </a>
                </div>
            </div>
        :
        ''
        )
    }
}

export default Edit
