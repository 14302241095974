import React, { Component } from 'react'
import fwarrow from './svg/fwarrow.svg'
import Note from './Note'

class Section extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = { deg: 90 }

        this.onLoad = true

        this.app = this.props.app

        this.toggleSection = this.toggleSection.bind(this)

        this.setNoteRef = (i, t) => {
            this.qrCodeNoteRef = t
        }
        this.qrCodeNoteElem = <Note content='Bitte QR-Code scannen um Rundgang zu starten!' setNoteRef={this.setNoteRef} />
    }



    componentDidMount() {

        if (this.onLoad && this.props.e.collapse === 'true') {
            this.onLoad = false
            this.closeSection()
        }
    }



    closeSection() {
        let list = document.getElementsByClassName(this.props.section)
        if (!list.length)
            return

        for (var i = 0; i < list.length; i++) {
            list[i].dataset.display = 'hide'
            list[i].style.transform = 'scale(0)'
            list[i].style.height = '0px'
        }
        this.setState({ deg: 0 })
    }



    toggleSection() {
        if (!this.app.validated) {
            this.qrCodeNoteRef.show()
            return
        }

        // nur andere sections toggeln, wenn unfinishedModal existiert
        let om = this.app.getOpenModal()
        if (om) {
            let same = om.checkit.section === this.props.name.replace(' ', '_')
            if (this.app.unfinishedModal() && same) {
                this.app.getOpenModal().notes['noText'].show()
                return
            }
        }


        let list = document.getElementsByClassName(this.props.section)
        let degr = 90

        if (!list.length)
            return

        for (var i = 0; i < list.length; i++) {
            let display = list[i].dataset.display === 'show'

            if (display) {
                list[i].dataset.display = 'hide'
                //list[i].style.transform = 'scale(0)'
                //list[i].style.height = '0px'
                list[i].style.maxHeight = '0px'
                list[i].style.paddingBottom = '0px'
                degr = 0
            } else {
                list[i].dataset.display = 'show'
                //list[i].style.transform = 'unset'
                //list[i].style.height = 'unset' // list[i].dataset.clientHeight + 'px'                              
                list[i].style.maxHeight = 'calc( 2 * (0.8 * (14px + 2.25vmin) + 60px + 1em) )' // list[i].dataset.clientHeight + 'px'                              
                list[i].style.paddingBottom = '10px'
            }
        }
        this.setState({ deg: degr })
    }

    render() {
        // console.log(this)
        return (
            <div>
                <div
                    className='Section pointer'
                    id={this.props.section}
                    style={{ opacity: this.props.disabled ? 0.25 : 1.0 }}
                    onClick={this.toggleSection}>
                    {this.props.name}
                    <img src={fwarrow} className='collapseIcon' style={{ transform: 'rotate(' + this.state.deg + 'deg)' }} alt='close' />

                </div>
                {this.qrCodeNoteElem}
            </div>

        )
    }
}

export default Section