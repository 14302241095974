import React, { Component } from 'react'
import Note from './Note'
import darrow from './svg/minimize.svg'
import digicam from './svg/digicam.svg'
import info from './svg/info.svg'
import pen from './svg/pen.svg'
import sPen from './svg/signaturePen.svg'
import {log} from '../helpers/PrintHTMLDebug'

const SHOW_MODAL = window.SHOW_MODAL //index.html

class Modal extends Component {
    constructor(props, context) {
        super(props, context)

        this.checkit = this.props.checkit
        this.app = this.props.app

        this.state = {
            msg: this.props.msg,
            datum: this.props.datum,
            descriptionHeight: 0,
            imagePath: ''
        }

        this.dismiss = false
        this.baseHeight = 220
        this.descriptionHeight = 0
        this.photoIcon = null
        this.signatureField = <img src={this.state.imagePath} className='signatureField' id={ this.props.parentID + 'Siganture' } alt='sign'/>
        this.savedContent = false

        this.notes = []
        this.tiggerCount = 0

        this.setNoteRef = (i, t) => {
            this.notes[i] = t
        }

        this.setDismiss = (s) => {
            this.dismiss = s
        }

        this.fotoNote = <Note content='Foto-Funktion demnächst verfügbar!' setNoteRef={this.setNoteRef} i={0} name='noPhotoYet'/>
        this.saveNote = <Note content='Bitte Text eingeben und speichern!' setNoteRef={this.setNoteRef} i={1} name='noText'/>

        this.handleChangeDatum = this.handleChangeDatum.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.getImage = this.getImage.bind(this)
        this.showImage = this.showImage.bind(this)
    }


    componentDidMount() {
        this.props.setModalRef(this)

        // TODO: delte height stuff?
        if (this.divElement){
            // console.log('this.divElement.clientHeight %o', this.divElement.clientHeight)
            this.setState({
                descriptionHeight: this.divElement.clientHeight
            })
        }

        // TODO: delte height stuff?
        if (this.refs.datum )
            this.setState({
                datumAreaHeight: this.refs.datum.clientHeight
            })

        // show json-cached image
        if(this.props.imagePath)
            this.showImage(this.props.imagePath)

        // console.log('this.descriptionHeight %s', this.descriptionHeight)
    }

    componentDidUpdate(){
        /* document.getElementsByClassName('header')[0].innerHTML = 'cDidUpdate: ' + this.props.signed  */
        
    }


    handleChange(event) {
        this.savedContent = false
        this.setState({ msg: event.target.value })
    }



    handleChangeDatum(event) {
        this.savedContent = false
        this.setState({ datum: event.target.value })
    }



    handleSave() {
        this.savedContent = true

        // show note if empty textarea
        let m = this.state.msg
        if (!m || typeof m === 'undefined') {
            this.notes['noText'].show()
            return
        }

        this.dismiss = true

        this.props.saveIntermediate()
        
        this.props.closeOthers(this.props.index - 1)

        if( this.props.mode !== 'signature' )
            this.props.openNext(this.props.index)
        this.forceUpdate()
    }



    closeModal(){
        
        let m = this.state.msg
        let c = this.checkit.state.choice
            c = c || c === 0

        let s = this.savedContent
        let ai = this.props.ai

        if ( !m && c && !s && !ai)  // show note
            return this.notes['noText'].show()
        
        this.dismiss = true

        this.props.saveIntermediate()

        this.props.closeThis()
        this.forceUpdate()
    }


    async getImage(id, e, mode) {

        let imagePath = "https://vignette.wikia.nocookie.net/asterix/images/9/9f/Asterix%2C_Obelix_%26_Dogmatix.png/revision/latest?cb=20110324122229"
        if (window.webkit){
            imagePath = await window.OpenCamera(id, mode)
            imagePath += '&srts=' + new Date().getTime()
        }

        log('GOT IMAGE imagePath: ' + imagePath, 'path', 'yellow')
        
        this.props.setImagePath( imagePath, this.props.index )
        this.showImage(imagePath)
    }



    showImage(imagePath){
        this.photoIcon    = this.refs.digicam 
        this.editImage    = this.refs.editImage
        this.signaturePen = this.refs.signaturePen

        this.photoIcon.style.padding    = '0px'
        this.photoIcon.style.display    = 'none'
        this.signaturePen.style.display = 'none'
        this.editImage.style.display    = 'block'
        this.photoIcon.style.display    = 'block'

        this.photoIcon.src = imagePath

        document.getElementById(this.props.parentID + 'ImgPreview').src = imagePath
    }



    setSignatureField(d){
        document.getElementById(this.props.parentID + 'Siganture').src = d

        this.setState({
            imagePath: d
        })
    }



    render() {
        // console.log('this.state.datumAreaHeight %o', this.state.datumAreaHeight)
        let showDatum = this.props.showDatum === 'true' ? {display: 'block'} : {}
        
        // info/descr bisher nur einzeilig
        let height = '70vh'  // this.state.mHeight + this.baseHeight + this.state.descriptionHeight + 'px' /* this.state.datumAreaHeight +  */
        let marginBottom = '70px'// '-8px'
        let borderWidth = '4px'
       
        // TODO alt, entfernen
        if ( !this.props.show || !SHOW_MODAL || this.dismiss){
            height = '0vh' // return null
            borderWidth = '0px'
        }else{
            // orientation portrait ?
            let portrait = window.matchMedia("(orientation: portrait)").matches || false
            height = portrait ? '70vh' : '100vh'
            // marginBottom= '0'
        }
        
        // TODO alt, entfernen
        if (this.props.show && SHOW_MODAL && !this.dismiss){
          
        }

        let name = this.props.itemData.name
        let desc = this.props.itemData.descr //this.props.itemData.modal

        let infoIcon = <img src={info} className="infoIcon" alt='info' />
        let eDescr = desc ? <div className='ModalDescription' ref='divElement'><div>{infoIcon}</div><div className='descr'>{desc}</div></div> : null

        /* if( desc )
            height= height +124 */

        let displaySignature = this.state.imagePath ? {} : { display : 'none' }
        let displayDigiCam = this.props.mode === 'signature' ? { display : 'none' } : {}

        /* if( this.divElement )
            console.log('this.divElement.clientHeight %o', this.divElement.clientHeight) */

        let show =  this.props.showAdditionalInputFields || this.state.msg || this.state.datum

        return (

            <div className='ModalContainer' style={{ maxHeight: height, marginBottom: marginBottom, borderBottomWidth: borderWidth, borderTopWidth: borderWidth }}>
                <div className='ModalHeader'>
                    {/* <span className="infoButton" >
                        <span className="CheckButton infoButtonIcon" style={{ display: 'inline-block', width: '15px', marginRight: '14px' }} alt="info">&#9701;</span>
                    </span> */}
                    {name}
                </div>
                <div className='ModalClose' onClick={this.closeModal}
                    style={{ transform: '' }}>
                    <img src={darrow} className="CheckButton" alt='close' />
                </div>
                {eDescr}
                <table>
                    <tbody>
                        <tr style={displaySignature} >
                            <td colSpan="2" style={{backgroundColor: 'white'}} >
                                <img 
                                    src={this.state.imagePath} 
                                    className='signatureField'
                                    id={ this.props.parentID + 'Siganture' } 
                                    onClick={(e) => { 
                                        this.getImage(this.props.parentID, e, this.props.mode) 
                                        // this.notes['noPhotoYet'].show() 
                                    }}
                                    alt='sign'
                                />
                            </td>
                        </tr>
                        <tr>
                            {this.props.buttons}
                        </tr>
                    </tbody>
                </table>
                <div className='textAreaContainer' style={{ maxHeight: show ? '358px' : '0px', display: this.props.ai ? 'none' : 'block' }}>
                    <textarea 
                        className='textArea' 
                        onChange={this.handleChange} 
                        onBlur={()=>{ /* this.props.checkFinished(); */ console.log('blur') }}
                        value={this.state.msg}
                    />
                    <textarea 
                        style={showDatum}
                        className='textAreaDatum' 
                        onChange={this.handleChangeDatum} 
                        ref='datum' placeholder='' 
                        onBlur={()=>{/*  this.props.checkFinished(); */ console.log('blur') }}
                        value={this.state.datum}
                    />
                    <img
                        src={digicam} 
                        style={displayDigiCam} 
                        className="DigiCam0" 
                        ref='digicam'
                        onClick={(e) => {
                            this.getImage(this.props.parentID, e, this.props.mode)
                            // this.photoIcon = e.target
                            // this.notes['noPhotoYet'].show()
                        }}
                        alt='cam'
                    /><img
                        src={sPen} 
            
                        className="PenTool" 
                        ref='signaturePen'
                        onClick={(e) => {
                            this.getImage(this.props.parentID, e, 'signature')
                            // this.photoIcon = e.target
                            // this.notes['noPhotoYet'].show()
                        }}
                        alt='cam'
                    />
                    <img
                        src={pen}
                        className='editImage'
                        ref='editImage'
                        onClick={(e) => {
                            this.getImage(this.props.parentID, e, this.props.mode)
                        }}
                        alt='edit'
                    />
                    {this.fotoNote}
                    <div
                        className='textAreaSave'
                        value={this.state.value}
                       
                        onClick={this.handleSave}
                    >
                        Speichern
                    </div>
                </div>
                
                {this.saveNote}
            </div>
        )
    }
}

export default Modal
