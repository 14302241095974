import React, { Component } from 'react'
import digicam from './svg/digicam.svg'
import cross from './svg/crossGrey.svg'
import { log, clog } from '../helpers/PrintHTMLDebug'

class PhotoAdd extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            photoAttached: false,
            zoom: false
        }

        this.app = this.props.app
        this.photoPreviewID = this.props.e.id

        this.onPhotoChange = this.onPhotoChange.bind(this)
        this.removePhoto = this.removePhoto.bind(this)
    }

    componentDidMount() {
        /* this.photoPreviewID = "photoPreview" + this.props.e.id */
    }

    onPhotoChange(evt) {

        let self = this

        var tgt = evt.target || window.event.srcElement,
            files = tgt.files

        if (FileReader && files && files.length) {
            var fr = new FileReader()
            fr.onload = function () {
                try {
                    let img = document.getElementById(self.photoPreviewID)
                    img.src = fr.result
                    self.setState({ photoAttached: true })
                    self.props.setImageRef(self.photoPreviewID, files[0])
                } catch (error) {
                    console.log(error)
                }
            }

            fr.onprogress = function (evt) {
                if (evt.lengthComputable) {
                    console.log(evt.total)
                    console.log(evt.loaded)
                }
            };

            clog('files[0]: %o', files[0])
            log('files[0].name: ' + files[0].name)
            log('files[0].type: ' + files[0].type)
            fr.readAsDataURL(files[0]);
        }
    }

    removePhoto() {
        try {
            document.getElementById(this.photoPreviewID).src = null
            this.props.resetImageRef(this.photoPreviewID)
            this.setState({ photoAttached: false })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        // this.photoPreviewID = "photoPreview" + this.props.e.id
        let displayPhoto = this.state.photoAttached || this.props.file ? { display: 'block' } : { display: 'none' }
        let zoomPhoto = this.state.zoom ? { /* maxWidth: '78vw' */ } : {}
        let inputID = this.props.e.id + 'InputFile'
        /* this.state.photoAttached ?  */

        return (
            <div className='PhotoAddRow' onClick={() => this.app.closeOthers(9999) }>
                <table className='PhotoAddTable' >
                    <tbody>
                        { this.state.photoAttached ? 
                        <tr>
                            <td className='PhotoAddNameTD'></td>
                            <td className='PhotoAddIconTD'>
                                <img className='PhotoAddIcon' src={cross} alt='+' onClick={this.removePhoto} />
                            </td>
                        </tr>
                        :
                        <tr>
                            <td className='PhotoAddNameTD' onClick={ () => this.InputFile.click() } ><span>{this.props.e.name}</span></td>
                            <td className='PhotoAddIconTD'>
                                <input type="file" name="filetoupload" id={inputID} className="inputfile" onChange={this.onPhotoChange} ref={(e) => this.InputFile = e} />
                                <label htmlFor={inputID}>
                                    <img className='PhotoAddIcon' src={digicam} alt='+' />
                                </label>
                            </td>
                        </tr>
                        }

                        <tr style={displayPhoto} className='aidentImageDisplay' >
                            <td>
                                <img src={this.props.file}  id={this.photoPreviewID} className='aidentImagePreview' alt='prev' style={zoomPhoto} onClick={ () =>this.setState({zoom: !this.state.zoom}) }/>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        )
    }
}

export default PhotoAdd


