import React, { Component } from 'react'

const BASEDOMAIN = window.location.hostname.split('.')[0]
const LOCALDEV = BASEDOMAIN.includes('192') || BASEDOMAIN.includes('localhost')
const UQRC_DEPLOYMENT = BASEDOMAIN.includes('uqrc')

const FORCE_RC = window.getURLParameter('forcerc') === 'true'
const RC = UQRC_DEPLOYMENT || LOCALDEV || FORCE_RC ? '&rc' : ''

class Menu extends Component {

    render() {
        let { url, query, app } = this.props
        let { VB, WD, CDAP } = app.type
        let { PROTOCOL } = app.mode

        let wd = function () { window.location.href = 'http://www.whatsdown.com' }
        let neu = function () { window.location.href = window.location.href + '?mode=checklist' }
        let neucdap = function () { window.location.href = window.location.href + '?mode=checklist' }
        let login = function () { window.location.href = url.VB_FBAUTH + query + RC + "&view=list"}
        let dfvb = function () { window.location.href = url.VB_FBAUTH + query + RC + "&view=list" }
        // let dfcdap = function () { window.location.href = url.CDAP_FBAUTH + query + RC }

        let wdBtn = WD ? <div className='introButton button' style={{ backgroundColor: '#ff7500' }} onClick={wd}><b>WhatsDown</b>-Störungsmelder öffnen</div> : null
        let logBtn = WD ? <div className='introButton button' style={{ backgroundColor: '#00ab63' }} onClick={login}><b>Arbeitsplatz</b>-Checkliste durchführen</div> : null
        let neuBtn = VB ? <div className='introButton button' style={{ backgroundColor: 'rgb(49, 127, 67)' }} onClick={neu}><b>Neuer Eintrag</b> in das Verbandsbuch <br/> <b>{app.displayTitle}</b></div> : null
        let neucdapBtn = CDAP ? <div className='introButton button' style={{ backgroundColor: 'rgb(0, 143, 255)' }} onClick={neucdap}> {/* in <b>{app.displayTitle}</b> */} eintragen<br/> </div> : null
        let dfvbBtn = VB ? <div className='introButton button' onClick={dfvb}><b>Verbandsbucheinträge</b> ansehen</div> : null
        let dfcdapBtn = CDAP ? <a className='userLogin' href={url.CDAP_FBAUTH + query + RC}>Login für Besitzer der Anwesenheitsliste</a> : null
        let novbBtn = VB ? <div className='introButton button' style={{ backgroundColor: '#bdbdbd' }}>Noch keine <b>Verbandsbucheinträge</b> vorhanden</div> : null
        let nocdapBtn = CDAP ? <a className='userLogin'  href={url.CDAP_FBAUTH + query + RC}>Login für Besitzer der Anwesenheitsliste</a> : null


        return (
            <div>
                {wdBtn}
                {neuBtn}
                {neucdapBtn}
                {logBtn}
                {PROTOCOL ? dfvbBtn : novbBtn}
                {PROTOCOL ? dfcdapBtn : nocdapBtn}
            </div>
        )
    }
}

export default Menu
