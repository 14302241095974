import React, { Component } from 'react'
import './css/Startpage.css'
import AGB from './AGB'
import cover from './svg/cdap_start2.svg'

class Startpage extends Component {
  render(){

    let showLoginAgain = this.props.app.getCookie('showLoginAgain_'+this.props.app.pid) === 'true'
    let logoutMessage = window.getURLParameter('logoutmessage')

    return (
      <div className="aiCenter">
        <div className="aiStartContainer">
          <p id='big'>
            <b>CORONA PRESENCE</b><br/>Einfach, flexibel und vollautomatisch!
          </p>
          <p>
            Mit <b>Corona Presence</b> wird Ihre Anwesenheit datenschutzkonform erfasst.
          </p>

          <img src={cover} id='cover' alt='cover'></img>

          {
            logoutMessage ? 
              <p style={{fontSize:'1.4em', marginBottom: 0}}>
                { logoutMessage }
              </p>
            :
            ''
          }

          <p style={{fontSize:'1.4em', marginBottom: 0}}>
            { showLoginAgain ? 'Erneut in ' : 'In '} Anwesenheitsliste von <br/><b>{this.props.app.displayTitle}</b>
          </p>
          

          {this.props.menu}
          
          { this.props.app.mode.INTRO ? 
              <div>
                <div id='aigo' className='button'  onClick={() =>{
                  let goto = window.getURLParameter('goto')
                  window.location.href = goto ? goto : this.props.goto }
                } >{"Und los geht's"}</div>
              </div>
              :
              ''
          }
            <AGB/>


        </div>
      </div>
    )
  }
}

export default Startpage
