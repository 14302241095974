import React, { Component } from 'react'
import qrcode from './svg/qr.svg'
import manikin from './svg/go.svg'
import Warning from './Warning'

import {log} from '../helpers/PrintHTMLDebug'

class StartButton extends Component {
    constructor(props, context) {
        super(props, context)        

        this.setNoteRef = (i, t) => {
            this.NoteRef = t
          }

          this.NoteElem = <Warning content='Falscher Standort!' setNoteRef={this.setNoteRef}/>
    }

    componentDidMount(){
        this.props.parent.setStartButtonRef(this.refs.startBtn)
    }

  render() {

    // this.props.parent.printDebug(...)
    log('StartButton.js: parent.validated: ' + this.props.parent.validated, false )

    let button = this.props.jsonData
    let parent = this.props.parent

    let mustBeValidated  = button.validatedAccess !== '' && !parent.validated
    let startButtonClass = mustBeValidated ? 'redBtn' : 'greenBtn'
    let startIcon        = parent.validated ? manikin : qrcode
    let startText        = parent.validated ? ( button.name || 'Anlagenrundgang starten' ) : ( button.invoke || 'Standort verifizieren')
    let href             = 'guide' in button ? button.guide.replace('%qrid', parent.pid) : ''
    let guideBtn         =  <div 
                                key={1} 
                                id='guideBtn' 
                                onClick={ () => console.log('guide href: %s', href)/*  this.DocNoteRef.show() */ } >
                                    <a href={href} target='_blank' rel="noopener noreferrer">
                                        {button.guideBtn}
                                    </a>
                            </div>

    return (
        <div id='startButtonContainer' >
            <div key={0} id='start' className={startButtonClass} ref='startBtn'                
                onClick={
                    () => {
                        //this.NoteRef.show() // zwecks DEBUGGING
                        //return // zwecks DEBUGGING

                        if( button.validatedAccess && !parent.validated ){
                            parent.getQRCode('starteRundgang')
                        }else{
                            // andere checkpunkte schließen und den ersten öffnen
                            parent.closeOthers(parent.firstElement)
                            if(parent.rows[parent.firstElement])
                                parent.rows[parent.firstElement].toggleOpen()
                        }
                        
                        // brandschutz (hat bisher keinen validated access)
                        if( typeof button.validatedAccess === 'undefined' ){
                            parent.setValidated(true)
                        }
                    }
                }>
                <img src={startIcon} className='startIcon' alt='start'/>
                <span>
                    {startText}
                </span>            
            </div>        
            { button.guide && button.guideBtn? guideBtn : '' }
            { this.NoteElem }
            { this.LocationNoteElem }        
        </div>
      )
  }
}  

export default StartButton


