module.exports = [
    {   
        id: 'header',
        name: 'A-Ident (Alpha Version)',
        type: 'header'
    },{   
        id: 'intro',
        name: 'A-Ident Header',
        text: 'Ihre Notfall-Daten zu ID ',
        post: 'Notfall-Daten zu ID ',
        type: 'introduction'
    },{
        id: 'name',
        name: 'Name',
        type: 'text'
    },{     
        id: 'phone',   
        name: 'Telefon',
        type: 'number'
    },{ 
        type: 'margin'
    },{     
        id: 'checkit',   
        name: 'Checkit',
        type: 'checkit',
        vals: [{
            cat: 'unacceptable',
            icon: 'thunder',
            bgcol: 'rgb(228, 64, 97)'
        },{
            cat: 'critical',
            icon: 'attention',
            bgcol: 'rgb(255, 175, 58)'
        },{
            cat: 'excellent',
            icon: 'checked',
            bgcol: 'rgb(75, 235, 154)'
        }]
    },{     
        id: 'checkit2',   
        name: 'Checkit2',
        type: 'checkit',
        vals: [{
            cat: 'unacceptable',
            icon: 'thunder',
            bgcol: 'rgb(228, 64, 97)'
        },{
            cat: 'critical',
            icon: 'attention',
            bgcol: 'rgb(255, 175, 58)'
        },{
            cat: 'excellent',
            icon: 'checked',
            bgcol: 'rgb(75, 235, 154)'
        }]
    },{     
        id: 'checkit3',   
        name: 'Checkit3',
        type: 'checkit',
        vals: [{
            cat: 'excellent',
            icon: 'checked',
            bgcol: 'rgb(75, 235, 154)'
        }]
    },{     
        id: 'checkit4',   
        name: 'Checkit4',
        type: 'checkit',
        vals: [{
            cat: 'unacceptable',
            icon: 'thunder',
            bgcol: 'rgb(228, 64, 97)'
        },{
            cat: 'excellent',
            icon: 'checked',
            bgcol: 'rgb(75, 235, 154)'
        }]
    },{ 
        type: 'margin'
    },{
        id: 'panr', 
        name: 'Personalausweis-Nr',
        type: 'text'
    },{        
        id: 'kknr',
        name: 'Krankenkassen-Nr',
        type: 'text'
    },{        
        id: 'emergency',
        name: 'Im Notfall anrufen',
        type: 'number'
    },{        
        id: 'info',
        name: 'Zusatz-Informationen',
        type: 'text'
    },{
        id: 'submit',
        name: 'Speichern',
        type: 'submit'
    }
]
