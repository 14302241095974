import React, { Component } from 'react'
import Loader from './Loader'


class PhotoDisplay extends Component {
    constructor(props, context) {
      super(props, context)

      this.state = {
          zoom: false,
          collapsed: false
      }
    }

    render() {
        let zoomPhoto = this.state.zoom ? { /* width: '93vw' */ } : {}
        let maxHeight = this.state.collapsed ? { maxHeight: '0' } : { maxHeight: '100vh' }
        let margin    = this.state.collapsed ? { marginBottom: '0', transitionDelay: '.4s' } : { marginBottom: '20px', transitionDelay: '.0s' }

        return (
            <div className="checkListItemRow"  >
              <div className="photoName" onClick={ ()=>this.setState({collapsed: !this.state.collapsed} )} style={margin} >
                {this.props.name ? this.props.name : ''}
              </div>
              <Loader ref='loader'/>
              <div className='photoViewContainer' style={maxHeight}>
                <img 
                    alt='Bild von mir'
                    src={this.props.file} 
                    className='photoView'
                    style={ zoomPhoto }
                    onLoad={ () => this.refs.loader.hide() }  
                    onClick={ () => this.setState({zoom: !this.state.zoom}) }
                 /> 
              </div>
            </div>
        )
    }
}

export default PhotoDisplay


