import React, { Component } from 'react'
import './css/Startpage.css'


class Startpage extends Component {
  render(){
    return (
      <div className="aiCenter">
        <div className="aiStartContainer">
       
          <p id='big'>
            <b>Undefined Startpage</b> - please talk to the developer!
          </p>
   
          


        </div>
      </div>
    )
  }
}

export default Startpage
