import React, { Component } from 'react'
import './css/AGB.css'
import attention from './svg/attention.svg'


class AGB extends Component {
  render(){
    return (
      <p className="agbContainer">
        <img id='img' src={attention} alt='!'/>
        <span id='txt'>
          Mit <b>{'Und los geht\'s'}</b> bestätigen Sie, dass Sie unsere <a href='https://edp.makrolog.de/edpmcm/mcm/_gKxcj5EI0L:FILE' id='datenschutz'>Datenschutzerklärung</a> und <a href='https://edp.makrolog.de/edpmcm/mcm/_72AjandVMN:FILE' id='agb'>Allgemeinen Geschäftsbedingungen</a> zur Kenntnis genommen haben.
        </span>
      </p>
    )
  }
}

export default AGB
