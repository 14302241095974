import React, { Component } from 'react'
import aidentIcon from './png/ainewlogo.png'
import boehringerIcon from './svg/boehringer.svg'
import christIcon from './svg/christ.svg'
import dwaIcon from './svg/dwa.svg'
import firstad from './variants/1stad/svg/1stad_logo.svg'
import menuIcon from './svg/menu.svg'
import wdText from './svg/whatsdown_schriftzug.svg'
import wdLogo from './png/wd_logo.png'
import cdaLogo from './svg/wdcda_uqrc_icon.svg'
import cdapLogo from './svg/cdalogo.svg'
import eagLogo from './svg/eag_logo.svg'
import noLogo from './svg/nologo.svg'
import adokLogo from './png/adok_logo.png'

import settings from '../config/settings.js'

class Header extends Component {
    constructor (props, context) {
        super(props, context)

        this.icon = []
        this.icon['adok']     = adokLogo
        this.icon['aident']     = aidentIcon
        this.icon['ai']     = aidentIcon
        this.icon['header']     = boehringerIcon // 'legacy'
        this.icon['boehringer'] = boehringerIcon
        this.icon['nologo']     = noLogo
        this.icon['christ']     = christIcon
        this.icon['dwa']        = dwaIcon
        this.icon['1stad']       = firstad
        this.icon['cda']       = cdaLogo
        this.icon['cdap']       = cdapLogo
       // this.icon['cdap']       = cdaLogo
        this.icon['wdeag']       = eagLogo

        this.local = settings.localhosts.includes( window.location.hostname ) ? ' (intern)' : ''
    }

  render() {

    let id   = this.props.id
    let name = this.props.app.type.VB && !this.props.app.mode.SETUP ? this.props.app.displayTitle : this.props.name
    let icon = this.icon[id]

    this.props.app.setHeaderIconUrl(icon)

    if( !icon )
        icon = this.icon['nologo']

    let borderRadius = {}
    if(this.props.isAIdent)
        borderRadius = { borderRadius: '800px', height: '78px', marginLeft: '2px', padding: '2px' }

    

    let header = null
    if ( id === 'whatsdown' )
        header = (
            <div id="WDHead">
                <img id="WDLogo" src={wdLogo} alt='logo'/>
                <div id='SVGHeadContainer'>
                    <img id="SVGHeadText" src={wdText} alt='logo'/>
                </div>                
                <div id="WDSubText">
                    QR-Code Setup { this.local /* this.props.pid */}
                </div>
            </div>
        )
    else
        header = (
            <div>
                <div id={id} className='header'>
                    <img 
                        style={borderRadius}
                        src={icon} 
                        id='headerIcon' 
                        alt='logo'
                        onClick={
                            () => { 
                                let debug = document.getElementById( settings.htmlDebugElementId )
                                if( debug )
                                    debug.style.display = 'block'
                            }
                        }
                    />
                        {name}
                    <img 
                        src={menuIcon} 
                        id='menuIcon'
                        alt='menu'
                        onClick={ () => document.getElementById("menu").classList.toggle("open") }
                    />
                </div>
                <div id='menu'>
                    menu open
                </div>
            </div>
        )

    return header
  }
}  

export default Header
