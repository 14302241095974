import React, { Component } from 'react'


class TextBox extends Component {

    render() {

        return (
            <div className='TextBox' style={this.props.css}>
                {this.props.message}
            </div>
        )
    }
}

export default TextBox


