import React, { Component } from 'react'
import Note from './Note'
import callIcon from './common/svg/call_green.svg'

class OldSchoolInput extends Component {
    constructor(props, context) {
        super(props, context)

        this.app = this.props.app

        this.state = {
            value: this.props.value || '',
            redBorder: false
        }

        this.setNoteRef = (i, t) => {
            this.qrCodeNoteRef = t
        }

        this.handleChange = (e) => {
            this.setState({ value: e.target.value })
        }

        this.toggleRedBorder = () => {
            let self = this

            setTimeout(function () {
                self.setState({ redBorder: self.state.value === '' && self.app.inspectedRequiredFields })
            }, 30)
        }

        this.onFocusHandler = this.onFocusHandler.bind(this)
        this.onKeyUpHandler = this.onKeyUpHandler.bind(this)

        this.qrCodeNoteElem = <Note content='Bitte QR-Code scannen um Rundgang zu starten!' setNoteRef={this.setNoteRef} />
    }


    componentDidMount() {
        this.required = this.props.required
        this.section = this.props.section

        if (this.props.id === 'deviceName')
            this.refs.devNameInput.focus()

        this.refs.thisElement.dataset.clientHeight = this.refs.thisElement.clientHeight
        this.refs.thisElement.dataset.display = 'show'
        // this.refs.thisElement.style.height = this.refs.thisElement.clientHeight + 'px'
    }


    onFocusHandler(e) {
        // console.log('focus')
        if (this.app.unfinishedModal()) {
            e.preventDefault()
            e.target.blur()
            this.app.getOpenModal().notes['noText'].show()
            return
        }
        this.app.closeOthers(9999)
        if (this.props.disabled) this.qrCodeNoteRef.show()
    }


    onKeyUpHandler(e) {

        if (e.keyCode === 13 && !this.props.multiline) {
            // blur
            e.target.blur()

            // safe parentSection & this.ID
            // let parentSection  = this.props.section // e.target.dataset.parentSection
            let currentInputID = e.target.id

            // check if next is in same section
            let inputList = {}
            try {
                inputList = document.querySelectorAll('.' + this.props.section + '>div>input')
            } catch (err) {
                console.log(err)
            }
            let focusNextInput = false
            let done = false

            Object.keys(inputList).forEach(function (key, index) {
                if (done)
                    return

                if (focusNextInput) {
                    this[key].focus()
                    done = true
                    return
                }

                if (this[key].id === currentInputID && index < inputList.length)
                    focusNextInput = true

            }, inputList)

        }

        this.app.checklistFinished()
        this.toggleRedBorder() // this.props.onKeyUp(e) // toggleRedBorder
    }


    render() {
        var re = new RegExp('_', 'g');

        let redBorder = this.state.redBorder /* || this.refs.thisElement.dataset.redBorder === 'true' */ ? '4px solid red' : '0px solid hsla(20, 99%, 85%, 1)'

        //  let value = this.props.value? this.props.value : null
        //let value = this.state.value? this.state.value : null
        let { type, id, name, multiline, required, options } = this.props
        let VIEW = this.app.mode.VIEW

        let pholder = name + ( required && !VIEW ? '*' : '')

        let incss = { color: '#535353', borderBottom: 'none' }
        let lightGray = this.state.value ? {} : { color: '#aaa'}

        let input = ''
        if( VIEW ){ // not editable render:
            let inputVariant = ''

            if( multiline ){
                inputVariant = <textarea readOnly className='multiLineInput' id={id} value={this.state.value}></textarea>
            }else{
                inputVariant = <div id={id} className='OldSchoolInputReadOnly' style={incss} >
                                {
                                    id === 'emergency' || name.includes('Notfall') ?
                                        <a href={'tel:' + this.state.value}>{this.state.value} <img src={callIcon} className='callIcon' alt='call' /></a>
                                        :
                                        this.state.value
                                }
                                </div>
            }

            input = <div>
                    <label htmlFor={id} className='OldSchoolInputLabel' >{pholder}: </label>
                    {
                        inputVariant
                    }
                </div>
        } else { // editable render
            let inputVariant = ''

            if( multiline ){
                inputVariant =  <textarea className='multiLineInput'
                                            id={id}
                                            ref='thisElement'
                                            value={this.state.value}
                                            onBlur={this.props.saveCurrent}
                                            onChange={this.handleChange}
                                            onKeyUp={this.onKeyUpHandler}
                                            onFocus={this.onFocusHandler}
                                            data-parent-section={this.props.section.replace(re, ' ')}
                                            required={this.props.required}
                                        >
                                </textarea>
            } else if ( type === 'select'){
                /* let jOptions = ''
                try {
                    jOptions = JSON.parse(options)
                } catch (error) {
                    inputVariant = 'invalid_options_json'
                } */

                let optionsList = options.map((e, i) => {
                    /* if(this.state.value === e.id )
                        return <option value={e.id} selected >{e.name}</option>
                    else */
                        return <option value={e.id} >{e.name}</option>
                })

                inputVariant = <div className='select-wrapper' style={{ transition: 'all 0.2s ease', border: redBorder }}>
                    <select 
                        value={this.state.value}
                        name={name} 
                        id={/* name.replace(/\s/g, '_') */id}
                        ref='thisElement'
                        style={lightGray} 
                        data-required={required}
                        onChange={(e)=>{this.app.checklistFinished();
                            this.toggleRedBorder();this.handleChange(e)}}
                    >
                    {optionsList}
                    </select>
                </div>

            } else{
                inputVariant =  <input type={type}
                                            id={id}
                                            style={{ transition: 'all 0.2s ease', border: redBorder }}
                                            className='OldSchoolInput'
                                            ref='thisElement'
                                            value={this.state.value}
                                            onBlur={this.props.saveCurrent}
                                            onChange={this.handleChange}
                                            onKeyUp={this.onKeyUpHandler}
                                            onFocus={this.onFocusHandler}
                                            data-parent-section={this.props.section.replace(re, ' ')}
                                            required={this.props.required}
                                            max="2030-06-10T00:00"
                                />
            }

            input = <div>
                        <label htmlFor={id} className='OldSchoolInputLabel' style={{ display: true ? '-webkit-inline-box' : 'none' }} >{pholder} </label>
                        { inputVariant }
                    </div>
        }

        if (this.props.id === 'deviceName') // WD Setup
            input = <input
                type={type}
                id={id}
                placeholder={this.props.name}
                className='OldSchoolInput'
                ref='devNameInput'
                onBlur={this.props.saveCurrent}
                onKeyUp={this.props.onKeyUp}
                onChange={this.handleChange}
            />

        return (
            <div className={this.props.section + ' OldSchoolInputContainer'} ref='thisElement'>
                <div style={{ opacity: this.props.disabled ? 0.25 : 1.0 }} >

                    {input}

                </div>
                {this.qrCodeNoteElem}
            </div>


        )
    }
}

export default OldSchoolInput


