import React, { Component } from 'react'
import './css/Startpage.css'
import AGB from './AGB'
import cover from './svg/cdap_logout.svg'

class Logout extends Component {
  render(){
    let app = this.props.app
    let cname = app.cookie.name
    let anonym = Boolean(app.getCookie(cname)) ? '' : '' // '(anonym)'

    return (
      <div className="aiCenter">
        <div className="aiStartContainer">
          <p id='big'>
            <b>CORONA PRESENCE</b><br/>Einfach, flexibel und vollautomatisch!
          </p>
          <p>
            Mit <b>Corona Presence</b> wird Ihre Anwesenheit datenschutzkonform erfasst.
          </p>
   
          <img src={cover} id='cover' alt='cover'></img>

          <p style={{fontSize:'1.4em', marginBottom: 0}}>
            Ihre aktuell gültige Anmeldung {anonym} in <br/><b>{this.props.app.displayTitle}</b>:<br/> <span className='lastLogin' >{app.getCookie('login_'+app.pid)}</span>
          </p>
          

            <div className='introButton button' style={{ backgroundColor: 'rgb(0, 143, 255)' }} onClick={ async (event)=> {
              event.preventDefault()

                    let msg
                    let clm = app.getCookie( 'logoutmessage_'+app.pid)
                    try {
                        msg = JSON.parse(clm)
                    } catch (err) {
                        msg = {subject: 'Failed to JSON.parse( logoutmessage ): ', body: clm }
                    }
                    await app.postJsonMessages2EDP(msg)


                    app.setCookie( 'logoutmessage_'+app.pid,'', 0)
                    app.setCookie('login_'+app.pid,'', -2)

                    window.location.reload()
                }
            }> abmelden <br/> </div> 

            <AGB/>


        </div>
      </div>
    )
  }
}

export default Logout
