import React, { Component } from 'react'
import AdStartpage from './variants/a-dok/Startpage'
import AiStartpage from './variants/a-ident/Startpage'
import BiStartpage from './variants/boehringer/Startpage'
import VbStartpage from './variants/1stad/Startpage'
import WdStartpage from './variants/whatsdown/Startpage'
import CdapStartpage from './variants/cdap/Startpage'
import Empty from './variants/empty/Startpage'

import Menu from './Menu'

const BASEDOMAIN = window.location.hostname.split('.')[0]
const LOCALDEV = BASEDOMAIN.includes('192') || BASEDOMAIN.includes('localhost')
const UQRC_DEPLOYMENT = BASEDOMAIN.includes('uqrc')

const FORCE_RC = window.getURLParameter('forcerc') === 'true'
const RC = UQRC_DEPLOYMENT || LOCALDEV || FORCE_RC ? '&rc/' : '/'

class Start extends Component {
    render() {
        let { url, app, menu } = this.props
        let { AI, AD, BI, VB, WD, CDAP } = app.type
        let query = '?service=' + app.type.SERVICE + '&qrid=' + app.pid
        let mbtn = menu ? <Menu url={url} query={query} app={app}/> : null
        

        let intro = <Empty/> //<AdStartpage app={app} goto={url.AD_FBAUTH + '?service=a-dok&qrid=' + app.pid} /> // default intro
        if (AD) intro = <AdStartpage app={app} menu={mbtn} goto={url.AD_FBAUTH + query + RC} />
        if (AI) intro = <AiStartpage app={app} menu={mbtn} goto={url.AI_FBAUTH + query} />
        if (BI) intro = <BiStartpage app={app} menu={mbtn} goto={url.BI_FBAUTH + query} />
        if (VB) intro = <VbStartpage app={app} menu={mbtn} goto={url.VB_FBAUTH + query + RC} />
        if (WD) intro = <WdStartpage app={app} menu={mbtn} goto={url.WD_FBAUTH + '&qrid=' + app.pid} />
        if (CDAP) intro = <CdapStartpage app={app} menu={mbtn} goto={url.CDAP_FBAUTH + '?qrid=' + app.pid} /> //TODO CDAP

        return app._componentDidMount ? intro : null 
    }
}

export default Start
