import React, { Component } from 'react'
import CheckBox from './CheckBox.js'

class Submit extends Component {

  render() {
      let classname = 'button submit' + ( this.props.active ? '' : ' off' )
      let func =  this.props.active ?  this.props.submit : null
      let css = this.props.hid.match(/^(aident|adok)$/) ? {backgroundColor:'linear-gradient(to right, #02aafe 0%, #177cfa 100%)'} : {}
      let {app} = this.props

      let box = ''
      let setcookieInJson = app.getRenderDataElement('setcookie')
      if (setcookieInJson && app.showCookieCheckbox){
        box = <CheckBox text='Daten auf diesem Gerät speichern' callback={(ca)=> {app.cookiesAllowed = ca; app.setCookie('cookiesAllowed', ca ? 'true' : 'false');app.saveInputsToCookie()}} init={app.cookiesAllowed}/>
        // show checkbox only once
        app.showCookieCheckbox = false
      }

      // if app.showCookieCheckbox
    return (
        <div >
            {box}
            <div    className={classname} 
                    id='submit'
                    onClick={ func } 
                    style={css}
            >
                {this.props.name}
            </div>

            {/* <div className='submit PDF'>
                PDF erstellen
            </div>
            <div className='submit Kunde'>
                An Kunden senden
            </div>
            </div> */} 
        </div>
      )
  }
}  

export default Submit


