import React, { Component } from 'react'
import adokMain from './svg/adok_logo.svg'
import aifamily from '../a-ident/svg/aifamily.svg'
import './css/Startpage.css'
import AGB from '../../common/AGB'

class Startpage extends Component {
  render(){
    return (
      <div className="aiCenter">
        <div className="aiStartContainer">
        <img src={adokMain} id="aiLogoConv" alt="aiLogoConv" />
        <img src={aifamily} id="aifamily" alt="aifamily" />


        {/* <img src={adokMain} id="adokMain" alt="adokMain" /> */}
          <p id='big'>
            <b>Dokumentation</b> - einfach - zuverlässig und immer dabei!
          </p>
          <p>
            Willkommen auf der Startseite von <b>A-Dok</b>.
          </p>
          <p>
            Mit <b>A-Dok</b> kann man wichtige Dokumente mit dem QR-Code verbinden. Über den QR-Code kannst Du dann überall mit Deinem Telefon auf die Dokumente zugreifen. 
          </p>
          <p>
            Eine gute Entscheidung für die digitale Dokumentenablage, denn einfach - zuverlässig und immer dabei ist besser.
          </p>
          <AGB/>
          <div id='aigo' className='button'  onClick={() =>{
            let goto = window.getURLParameter('goto')
            window.location.href = goto ? goto : this.props.goto }
          } >{"Und los geht's"}</div>
          <p id='kontakt'>
            <a id="ailink" href="http://dig-sie.de/KONTAKT/" rel="noopener noreferrer" >
                 
              <span>
                &nbsp;Kontakt
              </span>
            </a><br/>
            <a id="ailink" href="http://dig-sie.de/Impressum-und-AGB/" rel="noopener noreferrer" >
                 
              <span>
                &nbsp;Impressum
              </span>
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default Startpage

