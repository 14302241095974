import React, { Component } from 'react'
//import Loader from './Loader'


class PhotoDisplay extends Component {
    constructor(props, context) {
      super(props, context)

      this.state = {
          zoom: false,
          collapsed: false
      }
    }

    render() {
        //let zoomPhoto = this.state.zoom ? { width: '93vw' } : {}
        //let maxHeight = this.state.collapsed ? { maxHeight: '0' } : { maxHeight: '100vh' }
        let margin    = this.state.collapsed ? { marginBottom: '0', transitionDelay: '.4s' } : { marginBottom: '20px', transitionDelay: '.0s' }

        return (
            <div className="checkListItemRow"  >
              <div className="photoName" 
                onClick={ ()=>            
                    {
                        var win = window.open(this.props.file, '_blank');
                        win.focus()
                    }            
                } 
                style={margin} >
                    <span style={{textDecoration: 'underline'}} >Zusätzliches Dokument öffnen</span>
              </div>
            </div>
        )
    }
}

export default PhotoDisplay


