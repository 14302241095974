import React, { Component } from 'react'

class ShowTime extends Component {

  render() {
    return (
        <div>
            
            <div className='ShowTime'>
                {new Date().toLocaleString()} 
            </div>
            <div className='ShowTimeTitle'>
                {this.props.app.displayTitle}
            </div>
        </div>
        
      )
  }
}  

export default ShowTime


